import styled from 'styled-components';

export const LandscapeDetailSection = styled.section`
    background-color: #efe7da;
    .hero-landscape {
        .landscape-image {
            max-height: 560px;
            object-fit: cover;
        }
        .hero-content-box {
            background-color: white;
            width: 100%;
            max-width: 1065px;
            padding: 40px 52px;
            margin: -145px auto 0;
            position: relative;
            z-index: 9;
            .title-box {
                display: flex;
                justify-content: space-between;
                margin-bottom: 16px;
                h1 {
                    font-size: 48px;
                    line-height: 58px;
                }
                .small-title {
                    font-size: 19px;
                    line-height: 180%;
                    letter-spacing: 3.5px;
                    font-weight: 400;
                    color: ${({ theme }) => theme.colors.light_green};
                    text-transform: uppercase;
                }
            }
            .two-col-content {
                .text {
                    width: 100%;
                    max-width: 590px;
                    padding-right: 20px;
                    border-right: 1px solid #f6f6f6;
                    p {
                        margin-top: 0;
                    }
                }
                .list-icons {
                    padding-left: 16px;
                    li {
                        margin-bottom: 8px;
                        .icon-box {
                            width: 24px;
                            height: 24px;
                            margin-right: 12px;
                        }
                        &:last-child {
                            margin-bottom: 0;
                            .icon-box {
                                img {
                                    width: 18px;
                                }
                            }
                        }
                    }
                }
            }
            .landscape-partners {
                margin-top: 32px;
                .title {
                    font-weight: 600;
                }
                .partners-images {
                    margin-top: 16px;
                    gap: 28px;
                    .partner-img-box {
                        width: 110px;
                        height: auto;
                    }
                }
            }
        }
        .longWord {
            /* fallback option */
            word-break: break-all;
            /* use break-word if available */
            word-break: break-word;
            hyphens: auto;
        }
        .newLabel {
            position: absolute;
            top: 77px;
            right: 0px;
            width: 0;
            height: 0;
            border-top: 230px solid rgb(221, 107, 23); /* Adjust the height of the triangle */
            border-left: 230px solid transparent; /* Adjust the width of the triangle */
            z-index: 1;
            span {
                position: absolute;
                top: -184px; /* Adjust to center the text */
                right: -6px; /* Adjust to center the text */
                color: white;
                font-weight: bold;
                transform: rotate(45deg);
                font-size: 28px;
                text-align: center;
                letter-spacing: 1px;
            }
        }
    }
    .green-box {
        .title-box {
            max-width: 630px;
            margin: 0 auto;
            p {
                display: none;
            }
        }
    }

    .support-container {
        margin: 10px 0 20px;
    }

    @media (max-width: 1200px) {
        .hero-landscape {
            .hero-content-box {
                margin-top: -40px;
            }
        }
    }

    @media (max-width: ${({ theme }) => theme.media.tab}) {
        .hero-landscape {
            padding: 66px 0 0;
            .hero-content-box {
                margin: -60px 20px 0;
                border-radius: 20px;
                padding: 15px 15px 20px;
                width: auto;
                .title-box {
                    margin-bottom: 8px;
                    h1 {
                        font-size: 36px;
                    }
                    .small-title {
                        font-size: 17px;
                        line-height: 180%;
                    }
                }
                .landscape-partners {
                    .partners-images {
                        gap: 20px;
                        .partner-img-box {
                            height: auto;
                            width: 100px;
                            img {
                                width: 100%;
                                height: auto;
                            }
                        }
                    }
                }
            }
        }
    }
    @media (max-width: ${({ theme }) => theme.media.mobile}) {
        .hero-landscape {
            padding: 66px 0 0;
            .hero-content-box {
                margin-top: -40px;
                .two-col-content {
                    flex-wrap: wrap;
                    .list-icons {
                        margin: 18px 0 4px 0;
                    }
                }
            }
        }
    }
`;
export const PositiveImpact = styled.section`
    padding: 64px 4.8rem 78px;
    position: relative;
    text-align: center;
    background-color: #efe7da;
    .impact-info {
        max-width: 1065px;
        gap: 44px;
        .impact-group {
            .impact-info-numbers {
                gap: 12px;
                .total-numbers-info {
                    font-size: 48px;
                    line-height: 58px;
                    font-weight: 600;
                    span {
                        font-size: 19px;
                        line-height: 27px;
                        display: block;
                    }
                }
            }
        }
    }
    @media (max-width: ${({ theme }) => theme.media.tab}) {
        padding: 40px 22px 46px;
        .impact-info {
            gap: 28px;
            .impact-group {
                .grid {
                    gap: 32px;
                    .impact-info-numbers {
                        gap: 4px;
                        .total-numbers-info {
                            font-size: 42px;
                            line-height: 52px;
                            span {
                                margin: 0 auto;
                                max-width: 80%;
                                font-size: 17px;
                                line-height: 25px;
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const ThreeColLandscape = styled.section`
    background: #004d37 url('../images/bg-texture-green.jpg') no-repeat center center;
    background-size: cover;
    padding: 110px 20px 110px;
    position: relative;
    .three-cols-box {
        max-width: 1285px;
        margin: 0 auto;
        gap: 20px;
        .map-box {
            max-width: 522px;
            .img-box {
                overflow: hidden;
                .landscape-map {
                    // object-fit: cover;
                    width: 100%;
                    min-height: 580px;
                    border-radius: 15px;
                }
            }
        }
        .species-activities {
            min-width: 414px;
            gap: 20px;
            .three-col-box {
                background-color: white;
                box-shadow: 2px 4px 15px 0px rgba(0, 0, 0, 0.05);
                border-radius: 16px;
                padding: 24px 28px;
                .title-box.gray-color {
                    .small-title,
                    small {
                        color: #424a46;
                    }
                    small {
                        line-height: 150%;
                    }
                }
                .title-box {
                    small {
                        line-height: 150%;
                    }
                }
                .three-col-imgs {
                    margin-top: 16px;
                    gap: 8px;
                    .img-text-box {
                        img {
                            border-radius: 10px;
                            width: 114px;
                            height: 129px;
                            -o-object-fit: cover;
                            object-fit: cover;
                        }
                        .img-text {
                            margin-top: 4px;
                            line-height: 150%;
                            font-weight: 600;
                        }
                        .grey-color {
                            color: #424a46;
                        }
                    }
                }
            }
            .bg-lightgreen {
                background-color: #ddedd0;
            }
        }
        .network-lists {
            gap: 20px;

            .landscape-network {
                padding: 24px 28px;
                background-color: #6ea44c;
                border-radius: 16px;
                ul {
                    margin-top: 5px;
                    li {
                        padding: 4px 16px;
                        background-color: #ddedd0;
                        line-height: 18px;
                        font-weight: 600;
                        border-radius: 8px;
                        margin-top: 4px;
                        min-height: 36px;
                        gap: 8px;
                        &:first-child {
                            margin-top: 0;
                        }
                        .count {
                            background-color: #004d37;
                            padding: 1px 4px;
                            border-radius: 4px;
                        }
                    }
                }
            }
        }
        .recent-actions {
            h6 {
                font-size: 16px;
                line-height: 21px;
                color: white;
                margin-bottom: 12px;
                font-weight: 500;
            }
            .list-recent-action {
                gap: 12px;
                .article-thumb-section {
                    gap: 12px;
                    background-color: white;
                    max-height: 112px;
                    border-radius: 8px;

                    h6 {
                        color: ${({ theme }) => theme.colors.title_green};
                    }
                    img {
                        max-width: 80px;
                        min-height: 112px;
                        border-radius: 8px;
                        object-fit: cover;
                    }
                    .placeholderImg {
                        padding: 10px;
                    }
                    .action-content {
                        padding: 12px 12px 12px 0;
                        .link-text {
                            font-size: 16px;
                            line-height: 24px;
                            font-weight: 500;
                            color: ${({ theme }) => theme.colors.light_green};
                        }
                    }
                }
            }
        }
    }
    @media (max-width: 1300px) {
        .three-cols-box {
            .map-box {
                .img-box {
                    .landscape-map {
                        width: 100%;
                        object-fit: cover;
                    }
                }
            }
        }
    }
    @media (max-width: ${({ theme }) => theme.media.tab}) {
        padding: 20px 24px;
        .three-cols-box {
            flex-wrap: wrap;
            .map-box {
                max-width: 100%;
                width: 100%;
                .img-box {
                    .landscape-map {
                        width: 100%;
                        //min-height: auto;
                    }
                }
            }
            .species-activities {
                gap: 20px;
                width: 47%;
                flex: 1 1 auto;
                .three-col-box {
                    background-color: white;
                    box-shadow: 2px 4px 15px 0px rgba(0, 0, 0, 0.05);
                    border-radius: 16px;
                    padding: 24px 28px;
                    .title-box.gray-color {
                        .small-title,
                        small {
                            color: #424a46;
                        }
                        small {
                            line-height: 150%;
                        }
                    }
                    .title-box {
                        small {
                            line-height: 150%;
                        }
                    }
                    .three-col-imgs {
                        margin-top: 16px;
                        gap: 8px;
                        justify-content: center;
                        .img-text-box {
                            img {
                                border-radius: 10px;
                                width: 114px;
                                height: 129px;
                                -o-object-fit: cover;
                                object-fit: cover;
                            }
                            .img-text {
                                margin-top: 4px;
                                line-height: 150%;
                                font-weight: 600;
                            }
                            .grey-color {
                                color: #424a46;
                            }
                        }
                    }
                }
                .bg-lightgreen {
                    background-color: #ddedd0;
                }
            }
            .network-lists {
                width: 47%;
                flex: 1 1 auto;
            }
            .recent-actions {
                h6 {
                    font-size: 16px;
                    line-height: 25px;
                    margin-bottom: 0;
                }
            }
            .propose-box {
                background-color: #efe7da;
                border-radius: 8px;
                padding: 12px 20px;
                .green-link {
                    margin-top: 8px;
                    gap: 8px;
                    line-height: 28px;
                }
            }
        }
    }
    @media (max-width: ${({ theme }) => theme.media.mobile}) {
        .three-cols-box {
            .map-box {
                .img-box {
                    .landscape-map {
                        // height: 422px;
                    }
                }
            }
            .species-activities {
                min-width: auto;
                width: auto;
                gap: 12px;
                .three-col-box {
                    padding: 24px 20px;
                    border-radius: 20px;
                    .three-col-imgs {
                        justify-content: center;
                        .img-text-box {
                            width: 93px;
                            img {
                                border-radius: 10px;
                                width: 93px;
                                height: 129px;
                            }
                        }
                    }
                }
            }
        }
    }
    @media (max-width: 375px) {
        .three-cols-box {
            .species-activities {
                .three-col-box {
                    .three-col-imgs {
                        flex-wrap: wrap;
                        gap: 20px;
                    }
                }
            }
        }
    }
`;
export const ThanksOrganisation = styled.section`
    background-color: #f8f8f8;
    padding: 85px 4.8rem 120px;
    .box-partners {
        max-width: 1285px;
        margin: 0 auto;
        .content-box-partner {
            max-width: 405px;
            .action-btn {
                margin-top: 32px;
            }
        }
        .partner-logos-box {
            max-width: 550px;
            background-color: #fff;
            border-radius: 20px;
            box-shadow: 2px 4px 15px 0px rgba(0, 0, 0, 0.05);
            padding: 30px 57px 80px;
            .group-logos {
                gap: 15px;
                .img-box {
                    max-width: 200px;
                    margin: auto;
                    img {
                        width: 100%;
                    }
                }
            }
            .partner-tag {
                right: -70px;
                bottom: -73px;
            }
            .green-text {
                margin-bottom: 35px;
            }
        }
    }
    @media (max-width: ${({ theme }) => theme.media.tab}) {
        padding: 24px 20px;
        .box-partners {
            flex-wrap: wrap;
            .content-box-partner {
                width: 100%;
                max-width: 100%;
                .action-btn {
                    margin-top: 20px;
                }
            }
            .partner-logos-box {
                padding: 20px;
                margin-top: 40px;
                max-width: 100%;
                width: 100%;
                .group-logos {
                    gap: 10px;
                    .img-box {
                        width: 125px;
                    }
                }
                .partner-tag {
                    width: 113px;
                    right: -10px;
                    top: -70px;
                    bottom: auto;
                }
                .green-text {
                    line-height: 180%;
                    color: #6ea44c;
                    margin-bottom: 10px;
                }
            }
        }
    }
`;

export const LandscapeNav = styled.section`
    background-color: #ddedd0;
    padding: 60px 4.8rem;
    .group-next-prev {
        max-width: 1285px;
        margin: 0 auto;
    }
    @media (max-width: ${({ theme }) => theme.media.tab}) {
        padding: 22px 24px;
        .group-next-prev {
            gap: 25px;
        }
        background: url('../images/bg-texture-green.jpg') no-repeat center center;
    }
`;

export const SupportLandscape = styled.section`
    .stickyContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 auto;
    }

    .stickyTitle {
        font-weight: 800;
        font-size: 18px;
    }
    .stickyCall {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        background-color: white;
        padding: 0 10px;
        box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.12);
        z-index: 9999;
        animation: appear 0.3s ease-out;
    }
`;

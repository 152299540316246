import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { LightGreenBtn } from '../../../../newComponents/Button/ButtonSkin';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import MissionSubmenu from './submenu/MissionSubmenu';
import ContactSubmenu from './submenu/ContactSubmenu';
import LangSubmenu from './submenu/LangSubmenu';
import LoginSubmenu from './submenu/LoginSubmenu';
import { CgCloseR } from 'react-icons/cg';
import { IoIosArrowDown } from 'react-icons/io';
import mobileNav from '../../../../assets/newAssets/icons/mobile-nav.svg';
import { typography } from 'styled-system';

const StyledUsername = styled.span`
    ${typography}
    width: 45px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${({ theme }) => theme.colors.softGreen};
    border-radius: 50%;
    color: ${({ theme }) => theme.colors.actionGreen};

    & > :nth-child(1) {
        cursor: pointer;
    }
`;
const Nav = styled.nav`
    .navbar-list {
        gap: 100px;
    }

    .navbar-menu {
        gap: 32px;

        li {
            list-style: none;
            position: relative;

            .dropdown-login {
                top: 60px;
                ul {
                    .log-out {
                        border-top: 2px solid #ddedd0;
                        padding-top: 5px;
                        margin-top: 5px;
                    }
                }
            }

            .navbar-link {
                text-decoration: none;
                font-size: 16px;
                padding: 8px 0;
                font-weight: bold;
                color: ${({ theme }) => theme.colors.title_green};
                transition: color 0.3s linear;
                display: flex;
                align-items: center;
                cursor: pointer; /* Indicate the clickable nature */
                &:link,
                &:visited {
                    display: inline-block;
                    text-decoration: none;
                    font-size: 16px;
                    padding: 8px 0;
                    font-weight: bold;
                    color: ${({ theme }) => theme.colors.title_green};
                    transition: color 0.3s linear;
                    display: flex;
                    align-items: center;
                }

                &:hover,
                &:active {
                    color: ${({ theme }) => theme.colors.light_green};
                    .submenu {
                        display: block;
                    }
                    .arrow-btn {
                        transform: rotate(180deg);
                    }
                }

                .arrow-btn {
                    font-size: 1.3rem;
                    margin-left: 0.2rem;
                }
            }
            .submenu {
                display: none;
                width: auto;
                background: white;
                position: absolute;
                top: 34px;
                padding: 10px 0;
                box-shadow: 2px 4px 15px 0px rgba(0, 0, 0, 0.05);
                right: 0;
                z-index: 1001;
                border-radius: 8px;
                li {
                    a,
                    button {
                        padding: 10px 20px;
                        display: block;
                        font-size: 16px;
                        font-weight: 700;
                        white-space: nowrap;
                        color: ${({ theme }) => theme.colors.title_green};
                        &:hover {
                            background-color: ${({ theme }) => theme.colors.bgnavlink_hover};
                            ${({ theme }) => theme.colors.title_green};
                        }
                    }
                }
            }
        }
    }

    .mobile-navbar-btn {
        display: none;

        .close-outline {
            display: none;
        }
    }

    .mobile-navbar-btn[name='close-outline'] {
        display: none;
    }

    @media (max-width: ${({ theme }) => theme.media.desktop}) {
        .navbar-list {
            gap: 50px;
        }

        .navbar-menu {
            gap: 18px;

            li {
                .submenu {
                    li {
                        a,
                        button {
                            font-size: 14px;
                        }
                    }
                }

                .navbar-link {
                    &:link,
                    &:visited {
                        font-size: 14px;
                    }

                    .arrow-btn {
                        font-size: 1.2rem;
                    }
                }
            }
        }
    }

    @media (max-width: ${({ theme }) => theme.media.tab}) {
        .mobile-navbar-btn {
            display: inline-block;
            z-index: 999;
            border: ${({ theme }) => theme.colors.black};

            .mobile-nav-icon {
                font-size: 4.2rem;
                color: ${({ theme }) => theme.colors.black};
            }
        }

        /* hide the original nav menu  */
        .navbar-list {
            width: 100vw;
            height: 100vh;
            position: absolute;
            top: 0;
            left: 0;
            gap: 0;
            background-color: #fff;
            justify-content: flex-start;
            align-content: center;
            flex-direction: column-reverse;
            text-align: center;
            transform: translateX(100%);
            visibility: hidden;
            opacity: 0;
            padding-top: 65px;
            overflow-y: auto;

            .navbar-menu {
                flex-direction: column;
                width: 100%;
                gap: 0px;
                li {
                    width: 100%;
                    padding: 20px 0;
                    &:hover,
                    &:active {
                        background-color: ${({ theme }) => theme.colors.bgnavlink_hover};
                        padding: 20px 0;
                    }
                    a {
                        display: block !important;
                    }
                    .submenu {
                        position: relative;
                        top: 20px;
                        ul {
                            li {
                                padding: 0;
                            }
                        }
                    }
                }
            }
        }

        .active .mobile-nav-icon {
            display: none;
            font-size: 2.2rem;
            position: absolute;
            top: 20px;
            right: 20px;
            color: ${({ theme }) => theme.colors.black};
            z-index: 9999;
        }

        .active .close-outline {
            display: inline-block;
        }

        .active .navbar-list {
            visibility: visible;
            opacity: 1;
            transform: translateX(0);
            z-index: 999;
        }
    }
`;

const Navbar = ({ currentPath, user }) => {
    const { languages, selectedLanguage } = useSelector(storeState => storeState.Languages);
    const [openMenu, setOpenMenu] = useState(false);

    return (
        <Nav>
            <div className={openMenu ? 'menuGroup active' : 'menuGroup'}>
                <div className="navbar-list flex justify-space-between items-center">
                    <ul className="navbar-menu nav-left flex items-center">
                        <li className="mission-nav">
                            <div className="navbar-link" to="#">
                                <FormattedMessage id="NewLandingPage.header.joinMission" />
                                <IoIosArrowDown className="arrow-btn" />
                                <MissionSubmenu />
                            </div>
                        </li>
                        <li>
                            <NavLink
                                className="navbar-link"
                                onClick={() => setOpenMenu(false)}
                                to="/landscapes"
                            >
                                <FormattedMessage id="NewLandingPage.header.landscapes" />
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                className="navbar-link"
                                onClick={() => setOpenMenu(false)}
                                to="/about"
                            >
                                <FormattedMessage id="NewLandingPage.header.about" />
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                className="navbar-link"
                                onClick={() => setOpenMenu(false)}
                                to="/news"
                            >
                                Blog
                            </NavLink>
                        </li>
                        <li className="contact-nav">
                            <div className="navbar-link" to="">
                                <FormattedMessage id="NewLandingPage.header.contact" />{' '}
                                <IoIosArrowDown className="arrow-btn" />
                                <ContactSubmenu />
                            </div>
                        </li>
                    </ul>
                    <ul className="navbar-menu nav-right flex items-center">
                        <li className="language-nav">
                            <div className="navbar-link" to="">
                                {selectedLanguage.short} <IoIosArrowDown className="arrow-btn" />
                                <LangSubmenu />
                            </div>
                        </li>

                        {user ? (
                            <li>
                                <div className="navbar-link" to="">
                                    <StyledUsername>{user.initials}</StyledUsername>
                                    <IoIosArrowDown className="arrow-btn" />
                                    <LoginSubmenu />
                                </div>
                            </li>
                        ) : (
                            <>
                                <li>
                                    <NavLink
                                        className="navbar-link"
                                        to="/login"
                                        data-testid="btn-header-login"
                                    >
                                        <FormattedMessage id="NewLandingPage.header.login" />
                                    </NavLink>
                                </li>
                                <li>
                                    <LightGreenBtn>
                                        <NavLink to="/signup" data-testid="btn-header-signup">
                                            <FormattedMessage id="SignupForm.signUp" />
                                        </NavLink>
                                    </LightGreenBtn>
                                </li>
                            </>
                        )}
                    </ul>
                </div>
                {/* //nav icon */}
                <div className="mobile-navbar-btn">
                    <img
                        src={mobileNav}
                        alt="mobile-nav"
                        name="menu-outline"
                        className="mobile-nav-icon"
                        onClick={() => setOpenMenu(true)}
                    />
                    <CgCloseR
                        name="close-outline"
                        className="close-outline mobile-nav-icon"
                        onClick={() => setOpenMenu(false)}
                    />
                </div>
            </div>
        </Nav>
    );
};

export default Navbar;

import React, { useState, useRef, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, useHistory } from 'react-router-dom';
import { IoIosArrowBack } from 'react-icons/io';
import { IoIosArrowForward } from 'react-icons/io';
import { LightGreenBtn, OrangeBtn } from '../../../../newComponents/Button/ButtonSkin';

import { FaPlus } from 'react-icons/fa6';
import { FaMinus } from 'react-icons/fa6';
import { TbBallpen } from 'react-icons/tb';

import { RiDeleteBin6Line } from 'react-icons/ri';
import { MainForm, FormSection } from '../Styles';
import { LetterSignSection } from './Styles';
import { Document, Page as DocumentPage, pdfjs } from 'react-pdf';
import { useResizeObserver } from '@wojtekmaj/react-hooks';
import SignatureCanvas from 'react-signature-canvas';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const resizeObserverOptions = {};

const maxWidth = 600;
const DocumentSign = ({ documentUrl, pdfWidth, name, onSignatureDataUrlReady }) => {
    // const [image, setImage] = useState('');

    const [scale, setScale] = useState(1.0); // Default scale (zoom level)
    const [imageURL, setImageURL] = useState(null);
    const historyRef = useHistory();
    const inputFile = useRef(null);
    const sigCanvas = useRef({});

    // const handleFileUpload = e => {
    //     const { files } = e.target;
    //     if (files && files.length) {
    //         const filename = files[0].name;

    //         var parts = filename.split('.');
    //         const fileType = parts[parts.length - 1];
    //         console.log('fileType', fileType); //ex: zip, rar, jpg, svg etc.

    //         setImage(files[0]);
    //     }
    // };
    const handleImageUpload = event => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImageURL(reader.result); // Save the Data URL to state
            };
            reader.readAsDataURL(file); // Convert the file to a Data URL
        }
    };

    // Zoom in handler
    const zoomIn = () => {
        setScale(scale + 0.2); // Increase scale by 0.2
    };

    // Zoom out handler
    const zoomOut = () => {
        setScale(scale > 0.2 ? scale - 0.2 : 0.2); // Decrease scale by 0.2, but not below 0.2
    };
    const onButtonClick = () => {
        inputFile.current.click();
    };

    const clear = () => sigCanvas.current.clear();

    const generateSignature = () => {
        if (sigCanvas.current) {
            const canvas = sigCanvas.current.getCanvas();
            const context = canvas.getContext('2d');

            // Clear the canvas first
            clear();

            // Customize the font, size, and style of the text
            context.font = '30px Cedarville Cursive, cursive'; // Use a cursive or script font for a signature look
            context.fillStyle = 'black';

            // Generate the signature from the first and last name
            const signatureText = name;
            context.fillText(signatureText, 50, 100); // Adjust position as needed
        }
    };

    /* a function that uses the canvas ref to trim the canvas 
    from white spaces via a method given by react-signature-canvas
    then saves it in our state */
    const addSignature = () =>
        setImageURL(sigCanvas.current.getTrimmedCanvas().toDataURL('image/png'));
    //const signatureDataUrl = sigCanvas.current.toDataURL();

    const [containerRef, setContainerRef] = useState(null);
    const [containerWidth, setContainerWidth] = useState();

    const onResize = useCallback(entries => {
        const [entry] = entries;

        if (entry) {
            setContainerWidth(entry.contentRect.width);
        }
    }, []);

    const confirmInterest = () => {
        onSignatureDataUrlReady(imageURL);
    };

    useResizeObserver(containerRef, resizeObserverOptions, onResize);

    return (
        <FormSection>
            <div className="show-flex-tab justify-center breadcrumb mx-auto flex">
                <Link className="breadbrumb-link" to="/land-managers/role">
                    <FormattedMessage id="GuardianSubscription.StepForm.Step1.title" />
                </Link>

                <Link className="breadbrumb-link" to="/land-managers/signup">
                    <FormattedMessage id="GuardianSubscription.StepForm.FillDetails" />
                </Link>
                <Link className="breadbrumb-link active" to="#">
                    <FormattedMessage id="GuardianSubscription.StepForm.Step3.title" />
                </Link>
            </div>
            <div className="formnextprev justify-space-between relative w-full flex items-center">
                <button
                    className="prev-btn absolute flex items-center"
                    onClick={() => historyRef.push('/land-managers/signup')}
                >
                    <IoIosArrowBack />{' '}
                    <small>
                        <FormattedMessage id="Subscription.StepForm.previous" />
                    </small>
                </button>

                <button
                    className={`next-btn absolute flex items-center ${
                        !imageURL ? 'disable' : null
                    }`}
                    onClick={confirmInterest}
                >
                    <small>
                        <FormattedMessage id="Subscription.StepForm.next" />
                    </small>{' '}
                    <IoIosArrowForward />
                </button>
            </div>
            <LetterSignSection className="mx-auto flex flex-col">
                <div className="form-title">
                    <FormattedMessage id="GuardianSubscription.DocuSign.Doc.title" />
                </div>
                <div className="letter-sign flex justify-center">
                    <div className="letter-section relative">
                        <div className="group-btn absolute">
                            <button
                                className="w-full flex items-center justify-center"
                                onClick={zoomIn}
                            >
                                <FaPlus />
                            </button>
                            <button
                                className="w-full flex items-center justify-center"
                                onClick={zoomOut}
                            >
                                <FaMinus />
                            </button>
                        </div>
                        <div className="sign-letter">
                            <div
                                className={`letter-draft pdfDocument ${
                                    pdfWidth ? 'pdfSmallWidth' : ''
                                }`}
                                ref={setContainerRef}
                            >
                                <Document file={documentUrl}>
                                    <DocumentPage
                                        width={
                                            containerWidth
                                                ? Math.min(containerWidth, maxWidth)
                                                : maxWidth
                                        }
                                        pageNumber={1}
                                        scale={scale}
                                    />
                                </Document>
                            </div>
                            <div className="assignto">
                                <FormattedMessage
                                    id="GuardianSubscription.DocuSign.Doc.SignedBy"
                                    values={{
                                        name: (
                                            <>
                                                <strong>{name}</strong>
                                            </>
                                        ),
                                    }}
                                />

                                <strong>{new Date().toLocaleString()}</strong>
                            </div>
                            <input
                                style={{ display: 'none' }}
                                ref={inputFile}
                                onChange={handleImageUpload}
                                type="file"
                            />
                            <div className="signature-box relative flex items-center justify-center text-center">
                                <button className="actionbtn absolute small flex items-center">
                                    <RiDeleteBin6Line />
                                    <span className="btn-text">Clear</span>
                                </button>
                                <div onClick={onButtonClick}>
                                    {imageURL ? (
                                        <img
                                            src={imageURL}
                                            alt="my signature"
                                            className="signimg"
                                        />
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="right-sign flex justify-center flex-wrap">
                        {/* add Sign */}
                        <div className="sign-add w-full hide-tab">
                            <div className="small-title">
                                <FormattedMessage id="GuardianSubscription.DocuSign.DocSign.label" />
                            </div>
                            <div className="signcreate-box flex items-center justify-center">
                                <SignatureCanvas
                                    ref={sigCanvas}
                                    canvasProps={{
                                        className: 'sigCanvas',
                                    }}
                                />
                            </div>
                            <div className="sign-actions flex items-center">
                                <button
                                    className="actionbtn small flex items-center"
                                    onClick={generateSignature}
                                >
                                    <TbBallpen />
                                    <span className="btn-text">
                                        <FormattedMessage id="GuardianSubscription.DocuSign.Doc.Generate" />
                                    </span>
                                </button>
                                <button
                                    className="actionbtn small flex items-center"
                                    onClick={clear}
                                >
                                    <RiDeleteBin6Line />
                                    <span className="btn-text">
                                        <FormattedMessage id="GuardianSubscription.DocuSign.Doc.Clear" />
                                    </span>
                                </button>
                            </div>
                            <LightGreenBtn className="w-full" onClick={addSignature}>
                                <FormattedMessage id="GuardianSubscription.DocuSign.Doc.add" />
                            </LightGreenBtn>
                        </div>
                        {/* confirm interest */}
                        <OrangeBtn
                            className={`bottom ${!imageURL ? 'disabled' : null}`}
                            onClick={confirmInterest}
                        >
                            <FormattedMessage id="GuardianSubscription.DocuSign.Doc.Confirm" />
                        </OrangeBtn>
                    </div>
                </div>

                <div className="step-nav hide-tab flex mx-auto">
                    <div className="bullet"></div>
                    <div className="bullet"></div>
                    <div className="bullet current"></div>
                </div>
            </LetterSignSection>
        </FormSection>
    );
};

export default DocumentSign;

import styled from 'styled-components';

export const LetterSignSection = styled.section`
    .form-title {
        margin-bottom: 29px;
        font-weight: 600;
        line-height: 29px;
        @media (max-width: ${({ theme }) => theme.media.tab}) {
            text-align: center;
        }
    }
    .letter-sign {
        gap: 22px;
        .letter-section {
            padding: 25px 60px;
            background-color: #efe7da;
            flex: 1 1 auto;
            box-shadow: inset 4px 4px 14px 0px rgba(0, 0, 0, 0.1);
            .sign-letter {
                background: white;
                border-radius: 8px;
                box-shadow: 2px 4px 15px 0px rgba(0, 0, 0, 0.05);
                padding: 15px;
                .letter-draft {
                    font-size: 12px;
                }
                .assignto {
                    font-size: 9.4px;
                    margin-top: 25px;
                }
                .signature-box {
                    cursor: pointer;
                    font-weight: 600;
                    line-height: 29px;
                    color: #6ea44c;
                    background-color: #f8fbf6;
                    height: 100px;
                    border-radius: 12px;
                    border: 1px dashed #6ea44c;
                    margin-top: 7px;
                    .signimg {
                        max-height: 55px;
                    }
                    .actionbtn {
                        right: 0;
                        top: -5px;
                        font-weight: 600;
                        padding: 10px;
                        background-color: transparent;
                        color: #6ea44c;
                        line-height: 150%;
                        gap: 4px;
                        z-index: 999;
                        svg {
                            font-size: 20px;
                        }
                    }
                }
                .pdfDocument {
                    width: 100%; /* Make the container take full width */
                    max-width: 50vw; /* Prevent overflow of the viewport */
                    overflow-x: auto; /* Enable horizontal scrolling when zoomed in */
                    overflow-y: hidden; /* Optionally hide vertical overflow */
                    margin: 0 auto; /* Center the container */

                    justify-content: center; /* Center the PDF on the screen */
                }
                .pdfSmallWidth {
                    max-width: 25vw; /* Prevent overflow of the viewport */
                }
            }
            .group-btn {
                right: 12px;
                top: 12px;
                height: 73px;
                width: 40px;
                border-radius: 8px;
                z-index: 99;
                button {
                    background-color: white;
                    height: 36px;
                    border-bottom: 1px solid #d1e0c4;
                    font-size: 20px;
                    font-weight: bold;
                    color: #6ea44c;
                    border-radius: 8px 8px 0 0;
                    &:last-child {
                        border-bottom: 0 none;
                        border-radius: 0 0 8px 8px;
                    }
                }
            }
        }
        .right-sign {
            width: 100%;
            max-width: 301px;
            flex: 1 0 auto;
            .sign-add {
                padding: 12px 16px;
                border-radius: 8px;
                box-shadow: 3px 3px 20px 0px rgba(0, 0, 0, 0.07);
                align-self: flex-start;
                .signcreate-box {
                    min-height: 122px;
                    border-radius: 12px;
                    border: 1px dashed #6ea44c;
                    margin: 12px 0 4px;
                    .signimg {
                        max-width: 185px;
                    }
                    .sigCanvas {
                        width: 100%;
                    }
                }
                .sign-actions {
                    margin: 4px 0 12px;
                    gap: 16px;
                    .actionbtn {
                        font-weight: 600;
                        padding: 10px;
                        background-color: transparent;
                        color: #6ea44c;
                        line-height: 150%;
                        gap: 4px;
                        svg {
                            font-size: 20px;
                        }
                    }
                    .disabled {
                        background-color: transparent;
                        color: #cecece;
                    }
                }
            }
            .bottom {
                align-self: flex-end;
            }
        }
        @media (max-width: ${({ theme }) => theme.media.tab}) {
            display: block;
            margin: 0 -20px;
            gap: 0;
            .letter-section {
                max-width: none;
                padding: 20px;
                .group-btn {
                    box-shadow: 2px 4px 15px 0px rgba(0, 0, 0, 0.05);
                    button {
                        border-radius: 15px 15px 0 0;
                        &:last-child {
                            border-radius: 0 0 15px 15px;
                        }
                    }
                }
                .sign-letter {
                    max-width: none;
                    .signature-box {
                        height: 83px;
                    }

                    .pdfDocument {
                        width: 100%; /* Make the container take full width */
                        max-width: 100vw; /* Prevent overflow of the viewport */
                        overflow-x: auto; /* Enable horizontal scrolling when zoomed in */

                        margin: 0 auto; /* Center the container */

                        justify-content: center; /* Center the PDF on the screen */
                    }
                }
            }
            .right-sign {
                max-width: none;
                padding: 20px;
            }
        }
    }

    .cta {
        padding: 0 14px;
        max-width: 305px;
        margin-top: 30px;
        margin-bottom: 7px;
        text-align: center;
        button {
            width: 100%;
        }
        .login-cta {
            line-height: 150%;
            margin-top: 8px;
            a {
                font-weight: 600;
            }
        }
    }
    @media (max-width: ${({ theme }) => theme.media.tab}) {
        .form-title {
            margin: 10px auto;
        }
    }
`;

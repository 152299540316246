import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';

import Header from '../NewLandingPage/Sections/Header/Header';
import Footer from '../NewLandingPage/Sections/Footer/Footer';
import { Page } from '../../components';

import HeroSection from '../../newComponents/HeroSection/HeroSection';
import HowitWorks from './Sections/HowItWorks';
import ProtectLand from './Sections/ProtectLand';
import GuardianNetwork from './Sections/GuardianNetwork';
import GuardianRoleOptions from './Sections/GuardianRoleOptions';
import GuardianTestimonialContent from './Sections/GuardianTestimonials';
import arrowBendedHero from '../../assets/newAssets/guardians/arrow-bended-hero.svg';
import guardianHeroVideoImg from '../../assets/newAssets/guardians/guardian_video-hero-img.webp';
import guardianHeroVideoImgM from '../../assets/newAssets/guardians/guardian_video-hero-img-m.webp';
import GuardianFaqs from '../../newComponents/Faqs';

import { Link } from 'react-router-dom';
import { GuardianSection } from './Styles';
import styled from 'styled-components';

const JoinNetwork = styled.section`
    padding: 110px 4.8rem 100px;
    max-width: 1440px;
    margin: 0 auto;
    .two-col-box {
        max-width: 1065px;
        margin: 0 auto;
    }

    @media (max-width: 1138px) {
        padding: 26px 0 30px;
        .two-col-box {
            justify-content: center;
        }
    }
`;

const questionData = [
    {
        id: 1,
        question: 'How much do I get paid?',
        answer:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    },
    {
        id: 2,
        question: 'When do I get paid?',
        answer:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    },
    {
        id: 3,
        question: 'What is carbon offsetting?',
        answer:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    },
    {
        id: 4,
        question: 'How do I “restore Nature”?',
        answer:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    },
    {
        id: 5,
        question: 'Why do I need all this paperwork to sign up?',
        answer:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    },
    {
        id: 6,
        question: 'What roles can I choose?',
        answer:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    },
];

const GuardiansPage = ({ location, history, user, language }) => {
    // hero hanging arrow text
    const heroArrow = () => {
        return (
            <div className="flex arrow-hero">
                <div className="text">
                    <FormattedMessage id="GuardiansPage.Hero.Guardian" />
                </div>
                <img src={arrowBendedHero} alt="arrow" />
            </div>
        );
    };

    // styled

    const handleRoleSubmit = () => {
        history.push('/land-managers/role');
    };

    return (
        <Page>
            <Header currentPath={location.pathname} user={user} history={history} />
            <GuardianSection>
                <HeroSection
                    title={<FormattedMessage id="GuardiansPage.Hero.Title" />}
                    titleline={<FormattedMessage id="GuardiansPage.Hero.TitleLine" />}
                    titleafter={<FormattedMessage id="GuardiansPage.Hero.TitleAfter" />}
                    titleclass="text-white"
                    paratext={heroArrow()}
                    paraclass="text-white"
                    heroBg="green-bg relative"
                    isVideo
                    videoPath="https://regenera-public.s3.amazonaws.com/community/guardians-gregorio-shipetiari.mp4"
                    videoThumbnailD={guardianHeroVideoImg}
                    videoThumbnailM={guardianHeroVideoImgM}
                    alttxt="Guardians"
                    orangeBtn
                    btnText={<FormattedMessage id="GuardiansPage.Hero.SignupBtn" />}
                    btnLink="/land-managers/role"
                />

                <ProtectLand />

                <HowitWorks />

                <JoinNetwork>
                    <div className="two-col-box flex justify-space-between flex-wrap">
                        <GuardianNetwork lang={language} />
                        <GuardianRoleOptions handleRoleSubmit={handleRoleSubmit} />
                    </div>
                </JoinNetwork>
                <GuardianFaqs questionData={questionData} />

                <GuardianTestimonialContent testimonialClass="testimonial-box" />
            </GuardianSection>
            <Footer history={history} />
        </Page>
    );
};

const mapStateToProps = storeState => ({
    user: storeState.user.currentUser
        ? {
              initials: storeState.user.currentUser.attributes.profile.abbreviatedName,
              name:
                  storeState.user.currentUser.attributes.profile.firstName +
                  ' ' +
                  storeState.user.currentUser.attributes.profile.lastName,
          }
        : null,
    language: storeState.Languages.selectedLanguage.short,
});

export default connect(mapStateToProps)(withRouter(GuardiansPage));

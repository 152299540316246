import React, { useState, useRef, useEffect } from 'react';
import LogoImageMobile from '../../../../assets/newAssets/logo-regenera-negativo.svg';
import LogoImageWhite from '../../../../assets/newAssets/logo-regenera.svg';
import hamburger from '../../../../assets/newAssets/hamburger.svg';
import { ReactComponent as ArrowDown } from '../../../../assets/newAssets/arrow-down.svg';

import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { logout } from '../../../../ducks/Auth.duck';
import { document } from 'window-or-global';
import { changeLanguage } from '../../../../ducks/Languages.duck';
import { IntlContext } from '../../../../IntlContext';
import LogoImageGreen from '../../../../assets/newAssets/logo-regenera.png';
import ReactPixel from 'react-facebook-pixel';

import {
    StyledUsername,
    StyledDesktopListItem,
    StyledNavLink,
    StyledList,
    StyledContainer,
    StyledDesktopLogoContainer,
    StyledMobileLogoContainer,
    StyledMobileMenuContainer,
    StyledPopper,
    StyledMenuMobile,
    StyledMenuDesktop,
    MenuHeaderContainer,
    StyledButton,
    StyledLanguagesList,
    StyledListItem,
    StyledDropdownBox,
    StyledDropdown,
    StyledOption,
    StyledNavItem,
    MenuContainer,
    StyledMenuItem,
    StyledLogoutButton,
    StyledLoginMenuContainer,
    LoginContainer,
    MainHeader,
} from './Styles';
import { NavLink } from 'react-router-dom';
import Navbar from './Navbar';

const secondaryNavItems = [
    {
        path: '/#ourcommunity',
        title: <FormattedMessage id="NewLandingPage.header.becomeEarthling" />,
    },
    {
        path: '/#ourstory',
        title: <FormattedMessage id="NewLandingPage.header.ourStory" />,
    },
    {
        path: '/news',
        title: <FormattedMessage id="NewLandingPage.header.news" />,
    },
];

const primaryNavItems = [
    {
        path: '/',
        title: <FormattedMessage id="NewLandingPage.header.home" />,
    },
    {
        path: '/people',
        title: <FormattedMessage id="NewLandingPage.header.individuals" />,
    },
    {
        path: '/organizations',
        title: <FormattedMessage id="NewLandingPage.header.business" />,
    },
    {
        path: '/land-managers',
        title: <FormattedMessage id="NewLandingPage.header.landManagers" />,
    },
];

const NavItem = ({ path, title, active, bold, type, data, transparent, testId }) => {
    return (
        <StyledNavLink
            active={active}
            to={data ? { pathname: path, state: { ...data } } : path}
            bold={bold}
            fontSize={type === 'primary' ? { _: 1, xl: 2 } : [1]}
            fontWeight={type === 'primary' ? [6] : [3, 3, 4]}
            transparent={transparent}
            data-testid={testId}
        >
            {title}
        </StyledNavLink>
    );
};
const mapStateToProps = storeState => ({
    languages: storeState.Languages.languages,
    selectedLanguage: storeState.Languages.selectedLanguage,
});

const LoginMenu = connect(mapStateToProps, { logout })(({ items, user, logout }) => {
    const [isOpen, setIsOpen] = useState(false);
    const containerRef = useRef(null);
    const handleOutsideClick = e => {
        if (containerRef.current && !containerRef.current.contains(e.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [containerRef]);

    const open = () => {
        setIsOpen(!isOpen);
    };

    const close = () => {
        setIsOpen(false);
    };
    return (
        <StyledDropdown ref={containerRef}>
            <StyledButton onClick={open}>
                <StyledUsername fontSize={1} fontWeight={6}>
                    {user.initials}
                </StyledUsername>
            </StyledButton>

            {isOpen && (
                <MenuContainer>
                    <div style={{ padding: '10px 20px' }}>
                        <ul>
                            {items.map(item => (
                                <StyledMenuItem key={item.path}>
                                    <StyledNavItem
                                        to={
                                            item.path === '/profile-settings'
                                                ? '/profile-check'
                                                : item.path
                                        }
                                    >
                                        {item.label}
                                    </StyledNavItem>
                                </StyledMenuItem>
                            ))}
                        </ul>
                    </div>
                    <StyledLogoutButton onClick={() => logout()}>
                        <FormattedMessage id="NewLandingPage.header.logout" />
                    </StyledLogoutButton>
                </MenuContainer>
            )}
        </StyledDropdown>
    );
});

const Dropdown = connect(mapStateToProps, { changeLanguage })(
    ({ languages: items, changeLanguage, selectedLanguage, transparent }) => {
        const [isOpen, setIsOpen] = useState(false);
        const containerRef = useRef(null);
        const { switchToEnglish, switchToSpanish, switchToPortuguese } = React.useContext(
            IntlContext
        );

        useEffect(() => {
            //call switch langugae here
            if (selectedLanguage.short === 'EN') {
                switchToEnglish();
            } else if (selectedLanguage.short === 'ES') {
                switchToSpanish();
            } else {
                switchToPortuguese();
            }
        }, [selectedLanguage]);

        const handleOutsideClick = e => {
            if (containerRef.current && !containerRef.current.contains(e.target)) {
                setIsOpen(false);
            }
        };

        useEffect(() => {
            document.addEventListener('mousedown', handleOutsideClick);

            return () => {
                document.removeEventListener('mousedown', handleOutsideClick);
            };
        }, [containerRef]);

        const open = () => {
            setIsOpen(!isOpen);
        };

        const close = () => {
            setIsOpen(false);
        };

        return (
            <StyledDropdown ref={containerRef}>
                <StyledOption selected={true} onClick={open} transparent={transparent}>
                    {selectedLanguage.short}
                    <span style={{ marginLetf: 5 }}>
                        <ArrowDown />
                    </span>
                </StyledOption>
                {isOpen && (
                    <StyledDropdownBox>
                        {items.map(item => (
                            <StyledOption
                                onClick={() => {
                                    changeLanguage(item.short);
                                    close();
                                }}
                                key={`desk-${item.short}`}
                            >
                                {item.name}
                            </StyledOption>
                        ))}
                    </StyledDropdownBox>
                )}
            </StyledDropdown>
        );
    }
);

const NavbarMenu = ({ currentPath, user, transparent }) => {
    return (
        <>
            <StyledList>
                {secondaryNavItems.map(item => (
                    <li key={item.title}>
                        <NavItem
                            path={item.path}
                            title={item.title}
                            active={currentPath === item.path}
                            type="secondary"
                            transparent={transparent}
                        />
                    </li>
                ))}

                <div>
                    {user ? (
                        <StyledDesktopListItem>
                            <LoginMenu
                                user={user}
                                items={[
                                    {
                                        path: '/account/tus-listings',
                                        label: (
                                            <FormattedMessage id="NewLandingPage.header.myLandscapes" />
                                        ),
                                    },
                                    // {
                                    //   path: '/new-action',
                                    //   label: <FormattedMessage id="NewLandingPage.header.addItem" />,
                                    // },
                                    {
                                        path: '/profile-settings',
                                        label: (
                                            <FormattedMessage id="NewLandingPage.header.profileSettings" />
                                        ),
                                    },
                                ]}
                            />
                        </StyledDesktopListItem>
                    ) : (
                        <LoginContainer>
                            <NavItem
                                path="/login"
                                title={<FormattedMessage id="NewLandingPage.header.login" />}
                                active={currentPath === '/login'}
                                type="secondary"
                                transparent={transparent}
                                data={{ from: '/' }}
                                testId="btn-header-login"
                            />
                            <NavItem
                                path="/signup"
                                title={<FormattedMessage id="SignupForm.signUp" />}
                                active={currentPath === '/signup'}
                                type="secondary"
                                transparent={transparent}
                                data={{ from: '/' }}
                                testId="btn-header-signup"
                            />
                        </LoginContainer>
                    )}
                </div>
                <StyledDesktopListItem>
                    <Dropdown transparent={transparent} />
                </StyledDesktopListItem>
            </StyledList>
            <StyledList>
                {primaryNavItems.map(item => (
                    <li key={item.title}>
                        <NavItem
                            path={item.path}
                            title={item.title}
                            active={currentPath === item.path}
                            bold
                            type="primary"
                            transparent={transparent}
                        />
                    </li>
                ))}
            </StyledList>
        </>
    );
};

const MobileHeader = connect(mapStateToProps, { changeLanguage })(
    ({ currentPath, selectedLanguage, changeLanguage, languages, user }) => {
        const [showPopper, setShowPopper] = useState(false);
        const { switchToEnglish, switchToSpanish, switchToPortuguese } = React.useContext(
            IntlContext
        );

        useEffect(() => {
            //call switch langugae here
            if (selectedLanguage.short === 'EN') {
                switchToEnglish();
            } else if (selectedLanguage.short === 'ES') {
                switchToSpanish();
            } else {
                switchToPortuguese();
            }
        }, [selectedLanguage]);

        const showHandler = () => {
            setShowPopper(true);
        };

        const closeHandler = () => {
            setShowPopper(false);
        };

        return (
            <StyledMobileMenuContainer>
                {!showPopper ? (
                    <StyledLoginMenuContainer>
                        {user && (
                            <LoginMenu
                                user={user}
                                items={[
                                    {
                                        path: '/account/tus-listings',
                                        label: (
                                            <FormattedMessage id="NewLandingPage.header.myLandscapes" />
                                        ),
                                    },
                                    {
                                        path: '/new-action',
                                        label: (
                                            <FormattedMessage id="NewLandingPage.header.addItem" />
                                        ),
                                    },
                                    {
                                        path: '/profile-settings',
                                        label: (
                                            <FormattedMessage id="NewLandingPage.header.profileSettings" />
                                        ),
                                    },
                                ]}
                            />
                        )}
                        <StyledButton onClick={showHandler}>
                            <img src={hamburger} alt="hamburger" />
                        </StyledButton>
                    </StyledLoginMenuContainer>
                ) : (
                    <StyledPopper>
                        <MenuHeaderContainer>
                            <p>Menu</p>
                            <StyledButton onClick={closeHandler}>X</StyledButton>
                        </MenuHeaderContainer>
                        <StyledMenuMobile>
                            <NavbarMenu currentPath={currentPath} user={user} />
                        </StyledMenuMobile>
                        <StyledLanguagesList>
                            {languages.map((item, index) => (
                                <React.Fragment key={`mobile-${item.name}`}>
                                    {index > 0 && <li>-</li>}
                                    <StyledListItem
                                        active={
                                            selectedLanguage &&
                                            selectedLanguage.name.toLowerCase() ===
                                                item.name.toLowerCase()
                                        }
                                    >
                                        <StyledButton onClick={() => changeLanguage(item.short)}>
                                            {item.name}
                                        </StyledButton>
                                    </StyledListItem>
                                </React.Fragment>
                            ))}
                        </StyledLanguagesList>
                    </StyledPopper>
                )}
            </StyledMobileMenuContainer>
        );
    }
);

const DesktopHeader = ({ currentPath, user, transparent }) => {
    return (
        <StyledMenuDesktop>
            <NavbarMenu currentPath={currentPath} user={user} transparent={transparent} />
        </StyledMenuDesktop>
    );
};

const Header = ({ currentPath, user, transparent }) => {
    console.log('transparent', transparent);
    const [isSticky, setIsSticky] = useState(false);
    const options = {
        autoConfig: true,
        debug: false,
    };

    ReactPixel.init(process.env.REACT_APP_FACEBOOK_PIXEL_ID, undefined, options);

    useEffect(() => {
        ReactPixel.pageView();
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 0) {
                setIsSticky(true);
            } else {
                setIsSticky(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <>
            {/* <StyledContainer transparent={transparent}>
                <StyledMobileLogoContainer>
                    <a href="/">
                        <img src={LogoImageMobile} alt="logo" />
                    </a>
                </StyledMobileLogoContainer>
                <StyledDesktopLogoContainer>
                    <a href="/">
                        <img src={transparent ? LogoImageWhite : LogoImageMobile} alt="logo" />
                    </a>
                </StyledDesktopLogoContainer>
                <MobileHeader currentPath={currentPath} user={user} />
                <DesktopHeader currentPath={currentPath} user={user} transparent={transparent} />
            </StyledContainer> */}

            <MainHeader
                className={`nav-header flex justify-space-between items-center ${
                    isSticky ? 'sticky' : ''
                }`}
            >
                <NavLink to="/">
                    <img src={LogoImageGreen} alt="Regenera Logo" className="logo" />
                </NavLink>
                <Navbar currentPath={currentPath} user={user} />
            </MainHeader>
        </>
    );
};

export default connect(null)(injectIntl(Header));

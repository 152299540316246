import React, { useRef } from 'react';
import styles from './styles.module.css';
import BlogCard from '../BlogCard';
import AliceCarousel from 'react-alice-carousel';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { string, object, array, oneOfType } from 'prop-types';
import { IconSpinner } from '../../components';
import { withRouter } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { FormattedMessage } from 'react-intl';

const propTypes = {
    category: object,
    blogs: oneOfType([string, array]),
    history: object,
};

const BlogCategory = ({ category, blogs, history }) => {
    let CarouselListings = useRef(null);
    let allBlogs = undefined;
    if (blogs) {
        if (blogs.length === 2) {
            const modifiedBlogs = [...blogs, blogs[0]];
            allBlogs = modifiedBlogs.map((element, i) => {
                if (i == 2) {
                    return (
                        <BlogCard
                            key={uuidv4()}
                            imageUrl={element.blog_details.featuredImage}
                            title={element.title}
                            summary={element.blog_details.summary}
                            writer={element.writer.name}
                            publishDate={element.blog_details.publishDate}
                            readTime={element.blog_details.readTime}
                            tags={element.taxonomy.tags}
                            visible={false}
                            slug={element.slug}
                        />
                    );
                }
                return (
                    <BlogCard
                        key={uuidv4()}
                        imageUrl={element.blog_details.featuredImage}
                        title={element.title}
                        summary={element.blog_details.summary}
                        writer={element.writer.name}
                        publishDate={element.blog_details.publishDate}
                        readTime={element.blog_details.readTime}
                        tags={element.taxonomy.tags}
                        slug={element.slug}
                    />
                );
            });
        } else {
            allBlogs = blogs.map(element => {
                return (
                    <BlogCard
                        key={uuidv4()}
                        imageUrl={element.blog_details.featuredImage}
                        title={element.title}
                        summary={element.blog_details.summary}
                        writer={element.writer.name}
                        publishDate={element.blog_details.publishDate}
                        readTime={element.blog_details.readTime}
                        tags={element.taxonomy.tags}
                        slug={element.slug}
                    />
                );
            });
        }
    }

    const handleClickCategory = category => () => {
        history.push(`/news/s?q=${''}&category=${category}`);
    };

    return (
        <>
            <div className={styles.blogsCategory}>
                <div className={styles.categoryHead}>
                    <p className={styles.category}>{category.name}</p>
                    <p className={styles.readMore} onClick={handleClickCategory(category.name)}>
                        <FormattedMessage id="Blogs.Categories.linktext" />
                    </p>
                </div>

                <div className={styles.blogsForCategory}>
                    {allBlogs ? (
                        allBlogs
                    ) : (
                        <div
                            style={{
                                width: '100%',
                                height: '300px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <IconSpinner />
                        </div>
                    )}
                </div>
            </div>
            <div className={styles.blogsForCategoryMobile}>
                {allBlogs ? (
                    <>
                        <AliceCarousel
                            buttonsDisabled={true}
                            autoPlayInterval={2000}
                            dotsDisabled={true}
                            infinite={false}
                            disableAutoPlayOnAction={true}
                            autoPlay={false}
                            responsive={{
                                0: { items: 1, itemsFit: 'contain' },
                                767: { items: 2 },
                            }}
                            items={allBlogs}
                            ref={el => (CarouselListings = el)}
                        />
                        <button
                            className={styles.carouselBackButton}
                            onClick={() => CarouselListings.slidePrev()}
                        >
                            <FaChevronLeft className={styles.carouselChevron} />
                        </button>
                        <button
                            className={styles.carouselNextButton}
                            onClick={() => CarouselListings.slideNext()}
                        >
                            <FaChevronRight className={styles.carouselChevron} />
                        </button>
                    </>
                ) : (
                    <div
                        style={{
                            width: '100%',
                            height: '300px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <IconSpinner />
                    </div>
                )}
            </div>
        </>
    );
};
BlogCategory.propTypes = propTypes;
export default withRouter(BlogCategory);

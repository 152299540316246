import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import heroimage from '../../assets/detalleselva.jpg';
import {
  Page,
  LayoutSideNavigation,
  LayoutWrapperMain,
  LayoutWrapperSideNav,
  LayoutWrapperTopbar,
  LayoutWrapperFooter,
  PrivacyPolicy,
  Hero,
} from '../../components';
import config from '../../config';
import Header from '../../newPages/NewLandingPage/Sections/Header/Header';
import Footer from '../../newPages/NewLandingPage/Sections/Footer/Footer';
import { withRouter } from 'react-router-dom';

import SpanishPrivacyPolicy from './PrivacyPolicy.es';
import EnglishPrivacyPolicy from './PrivacyPolicy.en';
import PortuguesePrivacyPolicy from './PrivacyPolicy.pt';

import css from './PrivacyPolicyPage.css';


const PrivacyPolicyPageComponent = props => {
  const { scrollingDisabled, intl } = props;

  const locale = (intl && intl.locale);
  function FuncTranslate(){
    if(locale == 'en'){
      return <EnglishPrivacyPolicy/>
    
    }else if(locale == 'pt') {
      return <PortuguesePrivacyPolicy/>
    }
    else {
      return <SpanishPrivacyPolicy/>
    }
  }
    
  const tabs = [
    {
      text: intl.formatMessage({ id: 'PrivacyPolicyPage.privacyTabTitle' }),
      selected: true,
      linkProps: {
        name: 'PrivacyPolicyPage',
      },
    },
    {
      text: intl.formatMessage({ id: 'PrivacyPolicyPage.tosTabTitle' }),
      selected: false,
      linkProps: {
        name: 'TermsOfServicePage',
      },
    },
  ];
  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'PrivacyPolicyPage.schemaTitle' }, { siteTitle });
  const schema = {
    '@context': 'http://schema.org',
    '@type': 'WebPage',
    name: schemaTitle,
  };
  return (
    <Page title={schemaTitle} scrollingDisabled={scrollingDisabled} schema={schema}>
      <LayoutSideNavigation>
        <LayoutWrapperTopbar>
          <Header
            transparent
            currentPath={props.location.pathname}
            user={props.user}
            history={props.history}
          />

          <Hero
            title={<FormattedMessage id="PrivacyPolicyPage.heading" />}
            size="medium"
            image={heroimage}
          />
        </LayoutWrapperTopbar>
        <LayoutWrapperSideNav tabs={tabs} />
        <LayoutWrapperMain>
        
          <div className={css.content} >
          <FuncTranslate></FuncTranslate>
          {/* {FuncTranslate} */}
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer history={props.history} />
        </LayoutWrapperFooter>
      </LayoutSideNavigation>
    </Page>
  );
};

const { bool } = PropTypes;

PrivacyPolicyPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const user = state.user.currentUser
    ? {
        initials: state.user.currentUser.attributes.profile.abbreviatedName,
        name:
          state.user.currentUser.attributes.profile.firstName +
          ' ' +
          state.user.currentUser.attributes.profile.lastName,
      }
    : null;
  return {
    user,
    scrollingDisabled: isScrollingDisabled(state),
  };
};

const PrivacyPolicyPage = compose(
  withRouter,
  connect(mapStateToProps),
  injectIntl
)(PrivacyPolicyPageComponent);

export default PrivacyPolicyPage;

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { colors } from '../../../../theme';
import innovate from '../../../../assets/innovateColored.png';
import ministerio from '../../../../assets/ministerioColored.png';
import ncc from '../../../../assets/nccColored.png';
import sistemab from '../../../../assets/sistemab.png';
import tropforest from '../../../../assets/tropForestColored.png';
import gbcColored from '../../../../assets/logo-aliados/GBC.png';
import peruColored from '../../../../assets/logo-aliados/peru2021.jpg';

import { medium, vimeo, facebook, linkedin, earth2 } from '../../../../util/getIcon';
import { injectIntl } from 'react-intl';
import Icon from '../../../../newComponents/Icon';
// import earth from '../../../../assets/newAssets/Earth.svg';
//import earth2 from '../../../../assets/newAssets/Earth2.svg';
// import earth3 from '../../../../assets/newAssets/Earth3.svg';
// import earth4 from '../../../../assets/newAssets/Earth4.svg';
// import earth5 from '../../../../assets/newAssets/Earth5.svg';
// import earth6 from '../../../../assets/newAssets/Earth6.svg';
import Button from '../../../../newComponents/Button';
import { ExternalLink, NamedLink } from '../../../../components';
import { FormattedMessage } from 'react-intl';
import * as coreAPI from '../../../../coreApi';
import {
    GreenFooter,
    StyledContainer,
    LeftContainer,
    StyledBottomContainer,
    StyledLinksContainer,
    NavItem,
    StyledSmallPara,
    NavHeadingItem,
    StyledSmallHeading,
    StyledHeading,
    ImagesContainer,
    GridContainer,
    StyledImg,
    RightContainer,
    StyledList,
    SocialIcons,
    AllIconsContainer,
    CountryIcons,
    StyledInput,
    InputsContainer,
    ConsentContainer,
    Checkbox,
    SubmitButtonContainer,
    Message,
    InputContainer,
    MainLinks,
    StyledButton,
    StyledLanguagesList,
    StyledListItem,
    StyledSuccessMessage,
    StyledCheckIcon,
    CheckboxContainer,
    ErrorCheckbox,
    IconContainer,
    StyledLocationPopper,
    Triangle,
    StyledLink,
    NavHeading,
} from './Styles';
import { connect } from 'react-redux';
import { changeLanguage } from '../../../../ducks/Languages.duck';
import ReCAPTCHA from 'react-google-recaptcha';

const capitalize = word => {
    return word.slice(0, 1).toUpperCase() + word.slice(1);
};

const getItems = item => [
    { text: <FormattedMessage id="NewLandingPage.footer.about" />, path: item.path },
    {
        text: <FormattedMessage id={`NewLandingPage.footer.become${capitalize(item.label)}`} />,
        path: `${item.path}#become`,
    },
    {
        text: <FormattedMessage id="NewLandingPage.footer.testimonials" />,
        path: `${item.path}#testimonials`,
    },
    {
        text: <FormattedMessage id="NewLandingPage.footer.faqs" />,
        path: `${item.path}#faqs`,
    },
];

const footerItems = [
    { label: 'natureLover', path: '/people' },
    { label: 'guardians', path: '/land-managers' },
    { label: 'partners', path: '/organizations' },
];

export const ErrorMessage = styled(Message)`
    color: ${colors.lightPink} !important;
    position: absolute;
    top: -3px;
    left: 0px;
    @media screen and (max-width: 425px) {
        top: 0px;
        left: 0px;
    }
`;

const mapStateToProps = storeState => ({
    languages: storeState.Languages.languages,
    selectedLanguage: storeState.Languages.selectedLanguage,
});

const Footer = connect(mapStateToProps, { changeLanguage })(
    ({ languages, changeLanguage, selectedLanguage, history, ...props }) => {
        const [error, setError] = useState({ firstname: false, lastname: false, email: false });
        const [success, setSuccess] = useState(false);
        const [name, setName] = useState({ firstname: '', lastname: '' });
        const [email, setEmail] = useState('');
        const [isChecked, setIsChecked] = useState(false);
        const [messageStatus, setmessageStatus] = useState();
        const [isLoading, setIsLoading] = useState(false);
        const [submitTry, setSubmitTry] = useState(false);
        const [checkboxError, setCheckboxError] = useState(false);

        const reCaptchaRef = React.createRef();

        useEffect(() => {
            if (isChecked) setCheckboxError(false);
        }, [isChecked]);

        const handleAllianceRedirect = slug => {
            history.push(`/user/${slug}`);
        };

        const handleNameChange = e => {
            const target = e.target;
            setName({ ...name, [target.name]: target.value });
        };

        const handleEmailChange = e => {
            setEmail(e.target.value);
        };

        const currentLocale = props.intl.locale;

        const checkEmailSubscriptionCaptcha = async captchaToken => {
            console.log('Token', captchaToken);
            if (!!captchaToken && name.firstname !== '' && name.lastname !== '' && email !== '') {
                try {
                    console.log('in check email subscription');
                    await coreAPI.subscribeToNewsLetter(
                        {
                            firstname: name.firstname,
                            lastname: name.lastname,
                            email,
                            language: currentLocale,
                            statusIfNew: 'subscribed',
                            status: 'subscribed',
                        },
                        captchaToken
                    );
                    setmessageStatus('sended');
                    setIsLoading(false);
                    setSuccess(true);
                } catch (e) {
                    setmessageStatus('error');
                    setIsLoading(false);
                }
            }
        };

        useEffect(() => {
            if (name.firstname != '' && name.lastname != '' && email.length > 2) {
                setError({
                    firstname: name.firstname === '',
                    lastname: name.lastname.length === '',
                    email: !/.+@.+\.[A-Za-z]+$/.test(email),
                });
                setIsLoading(false);
            }
            if (!error.firstname && !error.lastname && !error.email) {
                setError(false);
            }
        }, [name.firstname, name.lastname, email]);

        const handleNewsletter = async e => {
            e.preventDefault();
            setSubmitTry(true);
            if (name.firstname != '' && name.lastname != '' && email.length > 2) {
                setError({
                    firstname: name.firstname == '',
                    lastname: name.lastname == '',
                    email: !/.+@.+\.[A-Za-z]+$/.test(email),
                });
                setIsLoading(false);
            }
            if (name.firstname.length == 0 && name.lastname.length == 0 && email.length < 2) {
                setError({ firstname: true, lastname: true, email: true });
                setmessageStatus('error');
                setIsLoading(false);
            }
            if (name.firstname.length == 0 || name.lastname.length == 0) {
                setError({ firstname: true, lastname: true, email: false });
                setmessageStatus('error');
                setIsLoading(false);
            }
            if (email.length < 2) {
                setError({ firstname: false, lastname: false, email: true });
                setmessageStatus('error');
                setIsLoading(false);
            }
            if (isChecked === false) {
                setmessageStatus('error');
                setCheckboxError(true);
                setIsLoading(false);
            }
            if (!error.firstname && !error.lastname && !error.email && isChecked == true) {
                setIsLoading(true);
                reCaptchaRef.current.execute();
                try {
                    await coreAPI.subscribeToNewsLetter({
                        firstname: name.firstname,
                        lastname: name.lastname,
                        email,
                        language: currentLocale,
                        statusIfNew: 'subscribed',
                        status: 'subscribed',
                    });
                    setmessageStatus('sended');
                    setIsLoading(false);
                    setSuccess(true);
                } catch (e) {
                    setmessageStatus('error');
                }
            }
        };

        const SuccessMessage = (
            <StyledSuccessMessage>Thank you for signing up!</StyledSuccessMessage>
        );

        return (
            <div>
                <div>
                    {/* <StyledHeading>
            <FormattedMessage id="NewLandingPage.subFooter.title" />
          </StyledHeading>
          <GridContainer>
            <div className="grid-item">
              <StyledLink
                
                to={`/user/5da7b385-c220-4af4-bd73-ce817e93f34a`}
              >
                <StyledImg src={ncc} alt="company" />
              </StyledLink>
            </div>
            <div className="grid-item">
              <StyledLink
                
                to={`/user/5da7b385-c220-4af4-bd73-ce817e93f34a`}
              >
                <StyledImg src={tropforest} alt="company" />
              </StyledLink>
            </div>
            <div className="grid-item">
              <StyledLink
                
                to={`/user/5da7b385-c220-4af4-bd73-ce817e93f34a`}
              >
                <StyledImg src={sistemab} alt="company" />
              </StyledLink>
            </div>
            <div className="grid-item">
              <StyledLink
                
                to={`/user/5da7b385-c220-4af4-bd73-ce817e93f34a`}
              >
                <StyledImg  src={innovate} alt="company" />
              </StyledLink>
            </div>
            <div className="grid-item">
              <StyledLink
                
                to={`/user/5da7b385-c220-4af4-bd73-ce817e93f34a`}
              >
                <StyledImg src={ministerio} alt="company" />
              </StyledLink>
            </div>
            <div className="grid-item">
              <StyledLink
                
                to={`/user/5da7b3b0-a8cc-4361-a3b9-bd08c29eafb9`}
              >
                <StyledImg  src={peruColored} alt="company" />
              </StyledLink>
            </div>
            <div className="grid-item">
              <StyledLink
                lastImage={true}
                
                to={`/user/5da7b650-6609-43fd-b677-5d93500b633b`}
              >
                <StyledImg  src={gbcColored} alt="company" />
              </StyledLink>
            </div>
          </GridContainer> */}

                    {/* <ImagesContainer
            gridTemplateColumns={{
              _: 'repeat(auto-fit, minmax(auto, 100px))',
              md: 'repeat(auto-fit, minmax(auto, 150px))',
              lg: 'repeat(auto-fit, minmax(auto, 100px))',
              xl: 'repeat(auto-fit, minmax(auto, 130px))',
            }}
            px={{ _: 100, lg: 36 }}
            mb={100}
            mt={50}
            gridColumnGap={{ _: '0px', lg: '40px' }}
            height={{ _: 'auto', lg: 140 }}
          >
            <StyledLink
              height={{ _: '60%', lg: '70%' }}
              to={`/user/5da7b385-c220-4af4-bd73-ce817e93f34a`}
            >
              <StyledImg height={{ _: '60%', lg: '70%' }} src={ncc} alt="company" />
            </StyledLink>
            <StyledLink
              height={{ _: '60%', lg: '70%' }}
              to={`/user/5da7b385-c220-4af4-bd73-ce817e93f34a`}
            >
              <StyledImg height={{ _: '60%', lg: '70%' }} src={tropforest} alt="company" />
            </StyledLink>
            <StyledLink
              height={{ _: '60%', lg: '70%' }}
              to={`/user/5da7b385-c220-4af4-bd73-ce817e93f34a`}
            >
              <StyledImg height={'100%'} src={sistemab} alt="company" />
            </StyledLink>
            <StyledLink
              height={{ _: '60%', lg: '70%' }}
              to={`/user/5da7b385-c220-4af4-bd73-ce817e93f34a`}
            >
              <StyledImg height={{ _: '60%', lg: '70%' }} src={innovate} alt="company" />
            </StyledLink> 
            <StyledLink
              height={{ _: '60%', lg: '70%' }}
              to={`/user/5da7b385-c220-4af4-bd73-ce817e93f34a`}
            >
              <StyledImg height={{ _: '60%', lg: '70%' }} src={ministerio} alt="company" />
            </StyledLink>
            <StyledLink
              height={{ _: '60%', lg: '70%' }}
              to={`/user/5da7b3b0-a8cc-4361-a3b9-bd08c29eafb9`}
            >
              <StyledImg height={'100%'} src={peruColored} alt="company" />
            </StyledLink>
            <StyledLink
              lastImage={true}
              height={{ _: '60%', lg: '70%' }}
              to={`/user/5da7b650-6609-43fd-b677-5d93500b633b`}
            >
              <StyledImg height={'100%'} src={gbcColored} alt="company" />
            </StyledLink>
          </ImagesContainer> */}
                </div>
                <GreenFooter pt={80} px={{ _: 40, md: 60 }} pb={60}>
                    <StyledContainer
                        gridTemplateColumns={{
                            _: 'repeat(auto-fit, minmax(250px, 1fr));',
                            md: 'repeat(auto-fit, minmax(350px, 1fr))',
                        }}
                    >
                        <LeftContainer>
                            <StyledLinksContainer>
                                {footerItems.map(footerItem => (
                                    <div key={footerItem.label}>
                                        <NavHeading>
                                            <FormattedMessage
                                                id={`NewLandingPage.footer.${footerItem.label}`}
                                            />
                                        </NavHeading>
                                        <StyledList>
                                            {getItems(footerItem).map(item => (
                                                <NavItem to={item.path}>{item.text}</NavItem>
                                            ))}
                                        </StyledList>
                                    </div>
                                ))}
                            </StyledLinksContainer>
                            <MainLinks mt={55}>
                                <NavHeadingItem to="/#ourstory">
                                    <FormattedMessage id="NewLandingPage.footer.ourStory" />
                                </NavHeadingItem>
                                <NavHeadingItem to="/news">
                                    <FormattedMessage id="NewLandingPage.footer.news" />
                                </NavHeadingItem>
                                <NavHeadingItem to="/faq">
                                    <FormattedMessage id="NewLandingPage.footer.faqs" />
                                </NavHeadingItem>
                            </MainLinks>
                        </LeftContainer>
                        <RightContainer>
                            <AllIconsContainer>
                                <div>
                                    <StyledSmallHeading>
                                        <FormattedMessage id="NewLandingPage.footer.findUs" />
                                    </StyledSmallHeading>
                                    <SocialIcons mt={16}>
                                        <ExternalLink href="https://www.facebook.com/peru.regenera/">
                                            <Icon icon={facebook} />
                                        </ExternalLink>
                                        <ExternalLink href="https://vimeo.com/natureservicesperu">
                                            <Icon icon={vimeo} />
                                        </ExternalLink>
                                        <ExternalLink href="https://medium.com/regenera">
                                            <Icon icon={medium} />
                                        </ExternalLink>
                                        <ExternalLink href="https://www.linkedin.com/showcase/regenera-peru/">
                                            <Icon icon={linkedin} />
                                        </ExternalLink>
                                    </SocialIcons>
                                </div>
                                <div>
                                    <StyledSmallHeading>
                                        <FormattedMessage id="NewLandingPage.footer.contactUs" />
                                    </StyledSmallHeading>
                                    <CountryIcons mt={12}>
                                        {/* <Icon icon={earth} /> */}
                                        <IconContainer>
                                            <Icon icon={earth2} />
                                            <StyledLocationPopper>
                                                <p>Cusco - Perú</p>
                                                <p>Av. El Sol 627-B. Of. 404</p>
                                                <p>
                                                    {process.env.REACT_APP_REPLY_TO_EMAIL_ADDRESS}
                                                </p>
                                            </StyledLocationPopper>
                                            <ReCAPTCHA
                                                ref={reCaptchaRef}
                                                size="invisible"
                                                sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                                                onChange={checkEmailSubscriptionCaptcha}
                                            />
                                            <Triangle />
                                        </IconContainer>
                                        {/* <Icon icon={earth3} />
                  <Icon icon={earth4} />
                  <Icon icon={earth5} />
                  <Icon icon={earth6} /> */}
                                    </CountryIcons>
                                </div>
                            </AllIconsContainer>
                            <StyledSmallHeading>
                                <FormattedMessage id="NewLandingPage.footer.subscribeTitle" />
                            </StyledSmallHeading>
                            {!success ? (
                                <div>
                                    <InputsContainer>
                                        {/** status -> default, success, failure */}
                                        <InputContainer
                                            status={error.firstname && 'failure'}
                                            pt={
                                                error.firstname || error.lastname || error.email
                                                    ? '45px'
                                                    : '15px'
                                            }
                                        >
                                            {error.firstname && (
                                                <ErrorMessage>
                                                    <FormattedMessage id="NewLandingPage.footer.errorMessage" />
                                                </ErrorMessage>
                                            )}
                                            <StyledInput
                                                type="text"
                                                status={error.firstname && 'failure'}
                                                placeholder="First Name"
                                                name="firstname"
                                                onChange={handleNameChange}
                                            />
                                        </InputContainer>
                                        <InputContainer
                                            status={error.lastname && 'failure'}
                                            pt={
                                                error.email || error.firstname || error.lastname
                                                    ? '45px'
                                                    : '15px'
                                            }
                                        >
                                            {error.lastname && (
                                                <ErrorMessage>
                                                    <FormattedMessage id="NewLandingPage.footer.errorMessage" />
                                                </ErrorMessage>
                                            )}
                                            <StyledInput
                                                type="text"
                                                status={error.lastname && 'failure'}
                                                placeholder="Last Name"
                                                name="lastname"
                                                onChange={handleNameChange}
                                            />
                                        </InputContainer>
                                        <InputContainer
                                            status={error.email && 'failure'}
                                            pt={
                                                error.email || error.firstname || error.lastname
                                                    ? '45px'
                                                    : '15px'
                                            }
                                        >
                                            {error.email && (
                                                <ErrorMessage>
                                                    <FormattedMessage id="NewLandingPage.footer.errorMessage" />
                                                </ErrorMessage>
                                            )}
                                            <StyledInput
                                                type="email"
                                                status={error.email && 'failure'}
                                                placeholder="Email"
                                                onChange={handleEmailChange}
                                            />
                                        </InputContainer>
                                    </InputsContainer>
                                    <ConsentContainer>
                                        <CheckboxContainer>
                                            {checkboxError ? (
                                                <ErrorCheckbox
                                                    type="checkbox"
                                                    checked={isChecked}
                                                    onChange={() => setIsChecked(!isChecked)}
                                                />
                                            ) : (
                                                <Checkbox
                                                    type="checkbox"
                                                    checked={isChecked}
                                                    onChange={() => setIsChecked(!isChecked)}
                                                />
                                            )}
                                            <StyledCheckIcon
                                                checked={isChecked}
                                                onClick={() => setIsChecked(!isChecked)}
                                            />
                                        </CheckboxContainer>
                                        <Message>
                                            <FormattedMessage id="NewLandingPage.footer.consentMessage" />
                                        </Message>
                                    </ConsentContainer>
                                    <SubmitButtonContainer width={{ _: '100%', md: 250 }}>
                                        <Button
                                            disabled={isLoading}
                                            inProgress={isLoading}
                                            onClick={handleNewsletter}
                                            variant="filled"
                                            size="md"
                                        >
                                            <FormattedMessage id="NewLandingPage.footer.subscribe" />
                                        </Button>
                                    </SubmitButtonContainer>
                                </div>
                            ) : (
                                SuccessMessage
                            )}
                        </RightContainer>
                    </StyledContainer>
                    <StyledBottomContainer mt={{ _: 36, lg: 36 }}>
                        <StyledSmallPara
                            justifyContent={{ _: 'center', lg: 'flex-start' }}
                            gridRow={{ _: 2, lg: 1 }}
                        >
                            <span>
                                <NamedLink name="PrivacyPolicyPage">
                                    <FormattedMessage id="NewLandingPage.footer.privacypolicy" />
                                </NamedLink>
                                &nbsp;/&nbsp;
                                <NamedLink name="TermsOfServicePage">
                                    <FormattedMessage id="NewLandingPage.footer.terms" />
                                </NamedLink>
                                &nbsp;
                                <FormattedMessage id="NewLandingPage.footer.copyright" />
                            </span>
                        </StyledSmallPara>
                        <StyledLanguagesList justifyContent={{ _: 'center', lg: 'flex-end' }}>
                            {languages.map((item, index) => (
                                <React.Fragment key={item.name}>
                                    {index > 0 && <li>-</li>}
                                    <StyledListItem
                                        index={index}
                                        active={
                                            selectedLanguage &&
                                            selectedLanguage.name.toLowerCase() ===
                                                item.name.toLowerCase()
                                        }
                                    >
                                        <StyledButton onClick={() => changeLanguage(item.short)}>
                                            {item.name}
                                        </StyledButton>
                                    </StyledListItem>
                                </React.Fragment>
                            ))}
                        </StyledLanguagesList>
                    </StyledBottomContainer>
                </GreenFooter>
            </div>
        );
    }
);

export default injectIntl(Footer);

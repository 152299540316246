import React, { useState, useEffect } from 'react';

import styled from 'styled-components';
import { Page } from '../../../components';

import Header from '../../NewLandingPage/Sections/Header/Header';

import {
    getAllCategories,
    getAllLandscapes,
    searchBlogs,
    getAllBlogsByTag,
    handleQueryLanguage,
} from '../utils';
import { connect } from 'react-redux';
import { withRouter, useParams, NavLink, Link } from 'react-router-dom';
import queryString from 'query-string';
import { IconSpinner } from '../../../components';

import { v4 as uuidv4 } from 'uuid';
import InfiniteScroll from 'react-infinite-scroll-component';
import Footer from '../../NewLandingPage/Sections/Footer/Footer';
import { FormattedMessage } from 'react-intl';

import BlogSearchSection from './BlogSearchSection';
import BlogPostCard from '../../../newComponents/Blog/BlogPostCard';
import BlogFilterCategory from '../../../newComponents/Blog/BlogFilterCategory';
import BlogFilterLandscape from '../../../newComponents/Blog/BlogFilterLandscape';
import BlogSubscribe from './BlogSubscribe/index';
import { IoIosArrowDown } from 'react-icons/io';
import closeIcon from '../../../assets/newAssets/icons/close-icon.svg';

// styled
const BlogSection = styled.section`
    background-color: #f8fbf6;
    .blog-lists-container {
        max-width: 1320px;
        padding: 50px 20px 105px;
        .blog-list-count {
            margin: 10px 0 10px;
        }
        .post-lists {
            flex-wrap: wrap;
            gap: 30px 20px;
        }
    }
    .no-result {
        font-size: 32px;
        line-height: 42px;
        color: #cecece;
        max-width: 630px;
        margin-top: 30px;
        font-weight: 600;
    }
    @media (max-width: ${({ theme }) => theme.media.tab}) {
        .blog-lists-container {
            padding: 45px 20px 38px;
        }

        .no-result {
            margin: 30px 0;
        }
    }
`;
const BlogMenu = styled.section`
    max-width: 1280px;
    .navbar-menu {
        gap: 12px;
        margin-right: 20px;
        .landscape-nav {
            .submenu {
                right: -115px;
            }
        }
        .category-nav {
            .submenu {
                right: -129px;
            }
            .navbar-link {
                background-color: ${({ theme }) => theme.colors.title_green};
            }
        }
        li {
            list-style: none;
            position: relative;
            .navbar-link {
                background-color: ${({ theme }) => theme.colors.light_green};
                border-radius: 25px;
                font-weight: 600;
                color: white;
                padding: 4px 12px;
                line-height: 29px;
                height: 35px;
                gap: 5px;
            }
            .submenu {
                display: none;
                min-width: 249px;
                width: auto;
                background: white;
                position: absolute;
                top: 35px;
                padding: 10px 12px;
                box-shadow: 2px 4px 15px 0px rgba(0, 0, 0, 0.05);
                right: -10px;
                border: 1px solid #fefefe;
                z-index: 2;
                border-radius: 8px;
                li {
                    margin: 4px 0;
                    padding: 0;
                    .checkbox-section {
                        input[type='checkbox'] {
                            width: 20px;
                            height: 20px;
                        }
                        .form-check-label {
                            text-wrap: nowrap;
                            line-height: 1.5;
                            color: ${({ theme }) => theme.colors.title_green};

                            cursor: pointer; /* Ensure label has pointer cursor */
                        }
                    }
                }
            }
            &:hover,
            &:active {
                color: ${({ theme }) => theme.colors.helper};
                .submenu {
                    display: block;
                }
            }
        }
        @media (max-width: ${({ theme }) => theme.media.tab}) {
            margin-bottom: 8px;
        }
    }
    .selected-multi {
        .selected-token {
            gap: 12px;
            .token {
                border-radius: 25px;
                border: 1px solid #004d37;
                height: 35px;
                padding: 3px 12px;
                font-weight: 600;
                color: ${({ theme }) => theme.colors.title_green};
                gap: 4px;
                &:hover {
                    border-color: black;
                }
                .close-icon {
                    font-size: 30px;
                }
                span {
                    text-wrap: nowrap;
                }
            }
            .close-button {
                border: none;
                background: transparent;
                cursor: pointer;
            }
            .remove {
                font-weight: 600;
                color: ${({ theme }) => theme.colors.title_green};
                padding: 3px 10px;
                border: none;
                background: none;
                cursor: pointer;
                &:hover {
                    border: 1px solid #ccc;
                    border-radius: 25px;
                }
            }
        }
        @media (max-width: ${({ theme }) => theme.media.tab}) {
            .selected-token {
                gap: 5px;
                .token {
                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }
    }
`;

const BlogList = props => {
    const title = <FormattedMessage id="Blogs.Search.title" />;
    const para1 = (
        <p>
            <FormattedMessage id="Blogs.Search.para" />
        </p>
    );

    const [categories, setCategories] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [selectedLandscapes, setSelectedLandscapes] = useState([]);
    const [selectedTag, setSelectedTag] = useState('');
    const [selectedWriter, setSelectedWriter] = useState('');
    const [landscapes, setLandscapes] = useState([]);
    const [blogItems, setBlogItems] = useState('');
    const [hasMore, setHasMore] = useState(true);
    const [after, setAfter] = useState('null');
    const [isInitialized, setIsInitialized] = useState(false); // New state to control the flow

    // const { search } = useParams();
    // console.log('search', search);
    // const values = queryString.parse(search);
    // const [eop, setEOP] = useState(false);

    const params = useParams();
    const { newsCategory, newsKeywords, newsLandscape, newsTag, newsWriter } = params; // You will have either newsSlug or keywords depending on the route
    console.log(newsCategory, newsKeywords);

    const searchString = (
        <FormattedMessage
            id="Blogs.Search.success"
            values={{
                keywords: <>{newsKeywords}</>,
            }}
        />
    );
    const searchStringTitle = newsKeywords ? searchString : title;
    const paraString = newsKeywords ? '' : para1;

    useEffect(() => {
        // Convert URL slug to an array if not null, otherwise initialize as empty array
        console.log('comesofrinitialize');
        const categories = newsCategory ? [newsCategory] : [];
        const landscapes = newsLandscape ? [newsLandscape] : [];
        const tag = newsTag ? newsTag : '';
        const writer = newsWriter ? newsWriter : '';

        setSelectedCategories(categories);
        setSelectedLandscapes(landscapes);
        setSelectedTag(tag);
        setSelectedWriter(writer);
        setIsInitialized(true); // Set flag to true after state is initialized
    }, [newsCategory, newsLandscape, newsTag, newsWriter]); // Empty dependency array to run only on component mount

    const handleRemoveSearch = () => {
        props.history.push('/news');
    };
    const handleRemoveTag = () => {
        setSelectedTag('');
    };
    const handleRemoveWriter = () => {
        setSelectedWriter('');
    };

    const handleCategoryChange = (categorySlug, isChecked) => {
        setSelectedCategories(prevSelectedCategories =>
            isChecked
                ? [...prevSelectedCategories, categorySlug]
                : prevSelectedCategories.filter(slug => slug !== categorySlug)
        );
    };

    const handleLandscapeChange = (landscapeSlug, isChecked) => {
        setSelectedLandscapes(prevSelectedLandscapes =>
            isChecked
                ? [...prevSelectedLandscapes, landscapeSlug]
                : prevSelectedLandscapes.filter(slug => slug !== landscapeSlug)
        );
    };

    const fetchMoreData = async () => {
        if (after === 'null') {
            return;
        }
        const queryLang = await handleQueryLanguage(props.language);

        let result;
        // if (values.tag) {
        //     result = await getAllBlogsByTag(queryLang, values.tag);
        // } else {
        //     if (values.category !== 'all') {
        //         result = await searchBlogs(queryLang, values.q, values.category, after);
        //     } else {
        //         result = await searchBlogs(queryLang, values.q, after);
        //     }
        // }

        result = await searchBlogs(
            queryLang,
            after,
            newsKeywords,
            selectedCategories,
            selectedLandscapes,
            selectedTag,
            selectedWriter
        );

        if (result.status === 'success') {
            setBlogItems(prevItems => [...prevItems, ...result.data.nodes]);
            if (result.data.pageInfo.hasNextPage === false) setHasMore(false);
            console.log('endcursor', result.data.pageInfo.endCursor);
            setAfter(result.data.pageInfo.endCursor);
        }
    };

    useEffect(() => {
        (async () => {
            const queryLang = await handleQueryLanguage(props.language);
            const categoriesResponse = await getAllCategories(queryLang);
            if (categoriesResponse.status === 'success') {
                setCategories(categoriesResponse.data);
            }
            const landscapesResponse = await getAllLandscapes(queryLang);
            if (landscapesResponse.status === 'success') {
                setLandscapes(landscapesResponse.data);
            }
        })();
    }, []);

    useEffect(() => {
        if (!isInitialized) {
            console.log('notinitializedyet');
            return; // Skip this effect if not initialized
        }
        (async () => {
            console.log('firstimer', newsKeywords, selectedCategories);
            setHasMore(true);
            const queryLang = await handleQueryLanguage(props.language);
            let result;

            result = await searchBlogs(
                queryLang,
                'null',
                newsKeywords,
                selectedCategories,
                selectedLandscapes,
                selectedTag,
                selectedWriter
            );

            if (result.status === 'success') {
                setBlogItems(result.data.nodes);
                if (result.data.pageInfo.hasNextPage === false) {
                    setHasMore(false);
                }
                setAfter(result.data.pageInfo.endCursor);
            }
        })();
    }, [
        newsKeywords,
        selectedCategories,
        selectedLandscapes,
        selectedTag,
        selectedWriter,
        isInitialized,
    ]);
    //, values.q, values.category, values.tag
    return (
        <Page>
            <Header
                currentPath={props.location.pathname}
                user={props.user}
                history={props.history}
            />

            <BlogSection>
                <BlogSearchSection title={searchStringTitle} subtitle={paraString} />
                <div className="blog-lists-container mx-auto">
                    <BlogMenu className="mx-auto flex flex-wrap items-start">
                        <ul className="navbar-menu nav-right flex items-center">
                            <li className="category-nav">
                                <NavLink className="navbar-link flex items-center" to="#">
                                    <FormattedMessage id="Blogs.List.category" />
                                    <IoIosArrowDown />
                                    <BlogFilterCategory
                                        categories={categories}
                                        onCategoryChange={handleCategoryChange}
                                        selectedCategories={selectedCategories}
                                    />
                                </NavLink>
                            </li>
                            <li className="landscape-nav">
                                <NavLink className="navbar-link flex items-center" to="#">
                                    <FormattedMessage id="Blogs.List.landscape" />
                                    <IoIosArrowDown />
                                    <BlogFilterLandscape
                                        landscapes={landscapes}
                                        onLandscapeChange={handleLandscapeChange}
                                        selectedLandscapes={selectedLandscapes}
                                    />
                                </NavLink>
                            </li>
                        </ul>
                        <div className="selected-multi">
                            <div className="selected-token flex flex-wrap">
                                {newsKeywords && (
                                    <div key={newsKeywords} className="token flex items-center">
                                        <span>
                                            <FormattedMessage
                                                id="Blogs.Search.success"
                                                values={{
                                                    keywords: <>{newsKeywords}</>,
                                                }}
                                            />
                                        </span>
                                        <button
                                            className="close-button"
                                            onClick={() => handleRemoveSearch()}
                                        >
                                            <img
                                                src={closeIcon}
                                                className="close-icon"
                                                alt="close"
                                            />
                                        </button>
                                    </div>
                                )}
                                {selectedTag && (
                                    <div key={selectedTag} className="token flex items-center">
                                        <span>{selectedTag}</span>
                                        <button
                                            className="close-button"
                                            onClick={() => handleRemoveTag()}
                                        >
                                            <img
                                                src={closeIcon}
                                                className="close-icon"
                                                alt="close"
                                            />
                                        </button>
                                    </div>
                                )}
                                {selectedWriter && (
                                    <div key={selectedWriter} className="token flex items-center">
                                        <span>{selectedWriter}</span>
                                        <button
                                            className="close-button"
                                            onClick={() => handleRemoveWriter()}
                                        >
                                            <img
                                                src={closeIcon}
                                                className="close-icon"
                                                alt="close"
                                            />
                                        </button>
                                    </div>
                                )}
                                {categories &&
                                    categories
                                        .filter(category =>
                                            selectedCategories.includes(category.slug)
                                        )
                                        .map(selectedCategory => (
                                            <div
                                                key={selectedCategory.slug}
                                                className="token flex items-center"
                                            >
                                                <span>{selectedCategory.name}</span>
                                                <button
                                                    className="close-button"
                                                    onClick={() =>
                                                        handleCategoryChange(
                                                            selectedCategory.slug,
                                                            false
                                                        )
                                                    }
                                                >
                                                    <img
                                                        src={closeIcon}
                                                        className="close-icon"
                                                        alt="close"
                                                    />
                                                </button>
                                            </div>
                                        ))}
                                {landscapes &&
                                    landscapes
                                        .filter(landscape =>
                                            selectedLandscapes.includes(landscape.slug)
                                        )
                                        .map(selectedLandscape => (
                                            <div
                                                key={selectedLandscape.slug}
                                                className="token flex items-center"
                                            >
                                                <span>{selectedLandscape.name}</span>
                                                <button
                                                    className="close-button"
                                                    onClick={() =>
                                                        handleLandscapeChange(
                                                            selectedLandscape.slug,
                                                            false
                                                        )
                                                    }
                                                >
                                                    <img
                                                        src={closeIcon}
                                                        className="close-icon"
                                                        alt="close"
                                                    />
                                                </button>
                                            </div>
                                        ))}
                                {(selectedCategories.length > 0 ||
                                    selectedLandscapes.length > 0 ||
                                    selectedTag ||
                                    newsKeywords ||
                                    selectedWriter) && (
                                    <button
                                        className="remove flex items-center"
                                        onClick={() => {
                                            setSelectedCategories([]);
                                            setSelectedLandscapes([]);
                                            props.history.push('/news');
                                        }}
                                    >
                                        <FormattedMessage id="Blogs.List.removeall" />
                                    </button>
                                )}
                            </div>
                        </div>
                    </BlogMenu>

                    <InfiniteScroll
                        dataLength={blogItems.length}
                        next={fetchMoreData}
                        hasMore={hasMore}
                        scrollThreshold={0.0}
                        loader={
                            <p style={{ textAlign: 'center' }}>
                                <IconSpinner />
                            </p>
                        }
                        endMessage={
                            <p style={{ textAlign: 'center' }}>
                                <b>
                                    {blogItems.length > 0 ? (
                                        <FormattedMessage id="Blogs.Search.endofpage" />
                                    ) : (
                                        <h2 className="no-result mx-auto text-center">
                                            <FormattedMessage id="Blogs.Search.notfound" />
                                        </h2>
                                    )}
                                </b>
                            </p>
                        }
                    >
                        <div className="blog-list-count">{blogItems.length} items</div>
                        <div className="post-lists flex justify-start">
                            {blogItems &&
                                blogItems.map(item => (
                                    <BlogPostCard
                                        key={uuidv4()}
                                        image={item.blog_details.featuredImage}
                                        title={item.title}
                                        category={item.taxonomy.category}
                                        landscapes={item.taxonomy.landscape}
                                        authorName={item.writer.name}
                                        postDate={item.blog_details.publishDate}
                                        slug={item.slug}
                                    />
                                ))}
                        </div>
                    </InfiniteScroll>
                </div>

                <BlogSubscribe />
            </BlogSection>

            <Footer />
        </Page>
    );
};

const mapStateToProps = storeState => ({
    user: storeState.user.currentUser
        ? {
              initials: storeState.user.currentUser.attributes.profile.abbreviatedName,
              name:
                  storeState.user.currentUser.attributes.profile.firstName +
                  ' ' +
                  storeState.user.currentUser.attributes.profile.lastName,
              email: storeState.user.currentUser.attributes.email,
              firstName: storeState.user.currentUser.attributes.profile.firstName,
              lastName: storeState.user.currentUser.attributes.profile.lastName,
              cartCount: storeState.user.currentUser.cartCount,
              currentRole: storeState.user.currentUser.currentRole,
              activatedRoles: storeState.user.currentUser.activatedRoles,
          }
        : null,
    language: storeState.Languages.selectedLanguage.short,
});

export default connect(mapStateToProps)(withRouter(BlogList));

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ExternalLink } from '../../components';
import css from './TermsOfService.css';

const PortugueseTermsOfService = props => {
    const { rootClassName, className } = props;
    const classes = classNames(rootClassName || css.root, className);

    // prettier-ignore
    return (
    <div className={classes}>
      <p className={css.lastUpdated}>Última atualização: 21 de outubro de 2019</p>

      <p>
      Estes Termos e Condições ("Termos", "Termos e Condições") regem a sua relação com o site <ExternalLink href={`https://${process.env.REACT_APP_BASE_URL}`}>{process.env.REACT_APP_BASE_URL}</ExternalLink> (o "Serviço") operado por Nature Services Peru SA ("nós" ou "nosso"), Identificado com RUC 20385016582. Leia estes Termos e Condições cuidadosamente antes de usar o Serviço. Seu acesso e uso do Serviço estão condicionados à sua aceitação e conformidade com estes Termos. Estes Termos se aplicam a todos os visitantes, usuários e outras pessoas que acessam ou usam o Serviço. Ao acessar ou usar o Serviço, você concorda em obedecer a estes Termos. Se você não concordar com qualquer parte dos termos, não poderá acessar o Serviço.

      </p>

      <h2>Assinaturas</h2>
      <p>Partes do Serviço são cobradas com base em assinatura ("Assinaturas"). Você será cobrado antecipadamente em uma base periódica ("Ciclo de faturamento"). Os ciclos de faturamento são estabelecidos trimestralmente, com base no tipo de plano de assinatura que você seleciona ao comprar uma assinatura.</p>
      <p>Ao final de cada Ciclo de Faturamento, sua Assinatura será renovada automaticamente nas mesmas condições, a menos que você a cancele ou a Nature Services Peru SA a cancele. Você pode cancelar a renovação de sua assinatura através da página de administração de sua conta online ou contatando a equipe de atendimento ao cliente da Nature Services Peru SA</p>
      <p>É necessário um método de pagamento válido para processar o pagamento da assinatura. Você deve fornecer à Nature Services Peru SA informações de cobrança precisas e completas, incluindo nome completo, endereço, estado, código postal, número de telefone e informações válidas sobre a forma de pagamento. Ao enviar tais informações de pagamento, você autoriza automaticamente a Nature Services Peru SA a cobrar todas as taxas de assinatura incorridas por meio de sua conta para tais instrumentos de pagamento.</p>
      <p>Caso não ocorra o faturamento automático por qualquer motivo, Nature Services Peru SA emitirá uma fatura eletrônica indicando que deve proceder manualmente, dentro de um determinado prazo, com o pagamento integral correspondente ao período de faturamento indicado na fatura.</p>

      <h2>Alterações de taxa</h2>
      <p>Nature Services Peru SA, a seu exclusivo critério e a qualquer momento, pode modificar as taxas de assinatura. Quaisquer alterações na taxa de assinatura entrarão em vigor no final do ciclo de faturamento atual.</p>
      <p>Nature Services Peru SA fornecerá a você uma notificação com antecedência razoável de qualquer alteração nas taxas de assinatura para dar-lhe a oportunidade de cancelar sua assinatura antes que tal alteração entre em vigor.</p>
      <p>Seu uso continuado do Serviço após a mudança na taxa de assinatura entrar em vigor constitui seu acordo em pagar o valor da taxa de assinatura modificada.</p>

      <h2>Reembolsos</h2>
      <p>Exceto quando exigido por lei, as taxas de assinatura pagas não são reembolsáveis.</p>

      <h2>Contente</h2>
      <p>Nosso serviço permite que você poste, crie um link, armazene, compartilhe e, de outra forma, disponibilize certas informações, textos, gráficos, vídeos ou outro material ("Conteúdo"). Você é responsável pelo Conteúdo que postar no Serviço, incluindo sua legalidade, confiabilidade e adequação.</p>
      <p>Ao postar Conteúdo no Serviço, você nos concede o direito e a licença para usar, modificar, executar, exibir, reproduzir e distribuir tal Conteúdo no e por meio do Serviço. Você retém todo e qualquer direito a qualquer Conteúdo que enviar, postar ou exibir no ou por meio do Serviço, e você é responsável por proteger esses direitos.</p>
      <p>Você declara e garante que: (i) o Conteúdo é seu (você o possui) ou tem o direito de usá-lo e nos concede os direitos e a licença previstos nestes Termos, e (ii) a publicação de seu Conteúdo no ou por meio do Serviço não viola os direitos de privacidade, direitos de publicidade, direitos autorais, direitos contratuais ou quaisquer outros direitos de qualquer pessoa.</p>

      <h2>Conta de usuario</h2>
      <p>Ao criar uma conta de usuário ("Conta") conosco, você deve nos fornecer informações precisas, completas e atualizadas em todos os momentos. O não cumprimento desta norma constitui uma violação dos Termos, o que pode resultar no cancelamento imediato da sua conta no nosso Serviço.</p>
      <p>Você é responsável por proteger a senha que usa para acessar o Serviço e por qualquer atividade ou ação sob sua senha, seja em nosso Serviço ou em um serviço de terceiros.</p>
      <p>Você concorda em não revelar sua senha a terceiros. Você deve nos notificar imediatamente ao tomar conhecimento de qualquer violação de segurança ou uso não autorizado de sua conta.</p>
      <p>Você não pode usar como nome de usuário o nome de outra pessoa ou entidade ou que não esteja legalmente disponível para uso, um nome ou marca registrada que está sujeito aos direitos de outra pessoa ou entidade sem a autorização correspondente, ou um nome que seja ofensivo, vulgar ou obsceno.</p>

      <h2>Propriedade intelectual</h2>
      <p>O Serviço e seu conteúdo original (excluindo Conteúdo fornecido pelos usuários), recursos e funcionalidade são e permanecerão propriedade exclusiva da Nature Services Peru SA e de seus licenciados. O Serviço é protegido por direitos autorais, marcas registradas e outras leis do Peru e de outros países. Nossas marcas registradas e nossa imagem comercial não podem ser utilizadas em relação a nenhum produto ou serviço sem o consentimento prévio por escrito da Nature Services Peru SA</p>

      <h2>Links para outros sites</h2>
      <p>Nosso Serviço pode conter links para sites ou serviços de terceiros que não são propriedade ou controlados pela Nature Services Peru SA Nature Services Peru SA não tem controle ou assume responsabilidade pelo conteúdo, políticas de privacidade ou práticas de sites ou serviços de terceiros. Além disso, você reconhece e aceita que Nature Services Peru SA não será responsável, direta ou indiretamente, por quaisquer danos ou perdas causados ​​ou supostamente causados ​​por ou em relação ao uso ou dependência dos referidos conteúdos, bens ou serviços disponibilizados. em ou através de tais sites ou serviços.</p>
      <p>Recomendamos fortemente que você leia os termos e condições e políticas de privacidade dos sites ou serviços de terceiros que você visita.</p>

      <h2>Terminação</h2>
      <p>Podemos encerrar ou suspender sua conta imediatamente, sem aviso prévio ou responsabilidade, por qualquer motivo, incluindo, mas não se limitando a, se você não cumprir os Termos.</p>
      <p>Após a rescisão, seu direito de usar o Serviço cessará imediatamente. Se você deseja cancelar sua conta, você pode simplesmente parar de usar o Serviço.</p>

      <h2>Lei Aplicável</h2>
      <p>Estes Termos serão regidos e interpretados de acordo com as leis do Peru, sem levar em consideração suas disposições sobre conflitos de leis.</p>
      <p>Nossa violação de qualquer direito ou disposição destes Termos não será considerada uma renúncia desses direitos. Se qualquer cláusula destes Termos for considerada inválida ou inexequível por um tribunal, as cláusulas restantes destes Termos permanecerão em vigor. Estes Termos constituem o acordo completo entre nós em relação ao nosso Serviço e substituem e substituem quaisquer acordos anteriores que possamos ter entre nós em relação ao Serviço.</p>

      <h2>Alterar</h2>
      <p>Nós nos reservamos o direito, a nosso exclusivo critério, de modificar ou substituir estes Termos a qualquer momento. Se uma revisão for material, tentaremos fornecer um aviso com pelo menos 30 dias de antecedência antes que os novos termos entrem em vigor. O que constitui uma alteração material será determinado a nosso exclusivo critério.</p>
      <p>Ao continuar a acessar ou usar nosso Serviço após essas revisões entrarem em vigor, você concorda em obedecer aos termos revisados. Se você não concordar com os novos termos, pare de usar o Serviço.</p>

      <h2>Entre em contato conosco</h2>
      <p>Se você tiver alguma dúvida sobre estes Termos, entre em contato conosco.</p>

    </div>
  );
};

PortugueseTermsOfService.defaultProps = {
    rootClassName: null,
    className: null,
};

const { string } = PropTypes;

PortugueseTermsOfService.propTypes = {
    rootClassName: string,
    className: string,
};

export default PortugueseTermsOfService;

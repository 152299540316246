import React, { useState, useEffect } from 'react';
import Header from '../../Header/Header';
import { withRouter, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import LeftNav from '../../LeftNav/LeftNav';
import RightNav from '../../RightNav/RightNav';
import { Frame, MobileComp } from '../../../Styles';
import { changeLanguage } from '../../../../../ducks/Languages.duck';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import rssParser from 'rss-parser';
import Image from '../../../../../newComponents/Image';
import SectionInfo from '../../../../../newComponents/SectionInfo';
import { ExternalLink } from '../../../../../components';
import { HomeMain, Title, WhatsNew, GridContainer } from './Styles';
import Footer from '../../../components/Footer';
import ImpactImage from '../../../components/ImpactImage';
import ProfileImage from '../../../../../newComponents/ProfileImage/profileImage';
import HeroImage from '../../../../../assets/newAssets/friends_profile.png';
import { FormattedMessage } from 'react-intl';
import { handleQueryLanguage, getTenLatestBlogs } from '../../../../../newContainers/Blogs/utils';

const mapStateToProps = storeState => ({
    user: storeState.user.currentUser
        ? {
              initials: storeState.user.currentUser.attributes.profile.abbreviatedName,
              name:
                  storeState.user.currentUser.attributes.profile.firstName +
                  ' ' +
                  storeState.user.currentUser.attributes.profile.lastName,
              userType: storeState.user.currentUser.attributes.profile.userType,
          }
        : null,
    language: storeState.Languages.selectedLanguage.short,
});

const NewMyNatureHomePage = connect(mapStateToProps, { changeLanguage })(
    ({ location, history, user, language }) => {
        const [posts, setPosts] = useState(null);

        const historyPath = useHistory();

        useEffect(() => {
            if (user && user.userType === 'empresa') {
                historyPath.push('/partner-profile/home');
            }
        }, [user]);

        useEffect(() => {
            let isComponentMounted = true;
            const fetchData = async () => {
                const queryLang = await handleQueryLanguage(language);
                const response = await getTenLatestBlogs(queryLang);
                if (response.status === 'success') {
                    setPosts(response.data);
                }
            };
            fetchData();
            return () => {
                isComponentMounted = false;
            };
        }, []);

        return (
            <Frame>
                <Header currentPath={location.pathname} user={user} history={history} />
                <LeftNav />
                <ProfileImage
                    title={
                        <>
                            Friend profile
                            <br />& account
                        </>
                    }
                    image={HeroImage}
                />
                <HomeMain>
                    <ImpactImage />
                    <WhatsNew>
                        <Title section>
                            <FormattedMessage id="MyNaturePage.Home.NewTitle" />
                        </Title>
                        <GridContainer>
                            {posts &&
                                posts.length > 0 &&
                                posts.slice(0, 4).map((post, i) => {
                                    return (
                                        <div className="grid-item">
                                            <div
                                                className="link"
                                                onClick={() => {
                                                    history.push(`/news/${post.slug}`);
                                                }}
                                            >
                                                <SectionInfo
                                                    heading={post.title}
                                                    data={post.blog_details.summary}
                                                    align="left"
                                                    color="black"
                                                    textSize="md"
                                                />
                                                <br />
                                                <br />
                                                <Image
                                                    source={post.blog_details.featuredImage}
                                                    title={post.title}
                                                    size="md"
                                                />
                                            </div>
                                        </div>
                                    );
                                })}
                        </GridContainer>
                        <br />
                    </WhatsNew>
                    <Footer />
                    <MobileComp>
                        <RightNav />
                    </MobileComp>
                </HomeMain>
                <RightNav />
            </Frame>
        );
    }
);
const MyNatureHomePage = compose(
    withRouter,
    connect(mapStateToProps),
    injectIntl
)(NewMyNatureHomePage);
export default MyNatureHomePage;

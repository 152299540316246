import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './PrivacyPolicy.css';

import ExternalLink from '../ExternalLink/ExternalLink';

const PortuguesePrivacyPolicy = props => {
    const { rootClassName, className } = props;
    const classes = classNames(rootClassName || css.root, className);

    // prettier-ignore
    return (
    <div className={classes}>
      <p className={css.lastUpdated}>Data de vigência: 1º de janeiro de 2019</p>

      <p>Nature Services Peru SA ("nós", "nosso") opera o site <ExternalLink href={`https://${process.env.REACT_APP_BASE_URL}`}>{process.env.REACT_APP_BASE_URL}</ExternalLink> (doravante, o "Serviço").</p>
      <p>Esta página informa sobre nossas políticas em relação à coleta, uso e divulgação de dados pessoais quando você usa nosso Serviço e as opções disponíveis para você em relação a esses dados.</p>
      <p>Usamos seus dados para fornecer e melhorar o Serviço. Ao usar o Serviço, você concorda com a coleta e uso de informações de acordo com esta política. A menos que esta Política de Privacidade defina o contrário, os termos nela utilizados têm os mesmos significados que os nossos Termos e Condições, disponíveis em <ExternalLink href={`https://${process.env.REACT_APP_BASE_URL}`}>{process.env.REACT_APP_BASE_URL}</ExternalLink></p>
      <h2>
            <span>Definições</span>
        </h2>
        <p>
            <span>Serviço</span>
        </p>
        <p>
            <span>O serviço é o site <ExternalLink href={`https://${process.env.REACT_APP_BASE_URL}`}>{process.env.REACT_APP_BASE_URL}</ExternalLink> operado pela Nature Services Peru SA</span>
        </p>
        <p>
            <span>Dados de uso</span>
        </p>
        <p>
            <span>Dados de uso são os dados coletados automaticamente, gerados pelo uso do Serviço ou pela infraestrutura do próprio Serviço (por exemplo, a duração da visita a uma página).</span>
        </p>
        <p>
            <span>Datos de uso</span>
        </p>
        <p>
            <span>Datos de uso son los datos recopilados autom&aacute;ticamente, generados por el uso del Servicio o por la propia infraestructura del Servicio (por ejemplo, la duraci&oacute;n de la visita a una p&aacute;gina).</span>
        </p>
        <p>
            <span>Cookies</span>
        </p>
        <p>
            <span>Cookies são pequenos arquivos armazenados em seu dispositivo (computador ou dispositivo móvel).</span>
        </p>
        <h2>
            <span>Coleta e uso de informações</span>
        </h2>
        <p>
            <span>Coletamos diferentes tipos de informações para diversos fins, a fim de fornecer e melhorar o Serviço para você.</span>
        </p>
        <h3>
            <span>Tipos de dados coletados</span>
        </h3>
        <h4>
            <span>Dados pessoais</span>
        </h4>
        <p>
            <span>Quando você usa nosso Serviço, podemos pedir que você nos forneça certas informações de identificação pessoal que podem ser usadas para contatá-lo ou identificá-lo ("Dados Pessoais"). As informações de identificação pessoal podem incluir, mas não se limitam a:</span>
        </p>
        <ul>
            <li>
                <span>Endereço de e-mail</span>
            </li>
            <li>
                <span>Nomes e sobrenomes</span>
            </li>
            <li>
                <span>Número de telefone</span>
            </li>
            <li>
                <span>Endereço, vila, província, código postal, cidade</span>
            </li>
        </ul>
        <p>
            <span>Podemos usar seus Dados Pessoais para entrar em contato com você a fim de fornecer-lhe fichas, materiais de marketing ou promocionais e outras informações que possam ser do seu interesse. Você pode optar por não receber parte ou todas essas comunicações clicando no link de cancelamento de inscrição, seguindo as instruções fornecidas na mensagem de e-mail que lhe enviamos ou entrando em contato conosco.</span>
        </p>
        <h4>
            <span>Dados de uso</span>
        </h4>
        <p>
            <span>Também coletamos informações sobre como o Serviço é acessado e usado ("Dados de Uso"). Estes Dados de Uso podem incluir informações como o endereço de protocolo da Internet do seu computador (por exemplo, endereço IP), tipo de navegador, versão do navegador, as páginas do nosso Serviço que você visita, a hora e a data da sua visita, o tempo gasto nessas páginas, exclusivo identificadores de dispositivo e outros dados de diagnóstico.</span>
        </p>
        <h4>
            <span>Cookie e dados de rastreamento</span>
        </h4>
        <p>
            <span>Usamos cookies e tecnologias de rastreamento semelhantes para rastrear a atividade do nosso Serviço e manter certas informações.</span>
        </p>
        <p>
            <span>Cookies são arquivos com uma pequena quantidade de dados que podem incluir um identificador exclusivo anônimo. Os cookies são enviados para o seu navegador a partir de um site e armazenados no seu dispositivo. Outras tecnologias de rastreamento também utilizadas são beacons, tags e scripts para coletar e rastrear informações, bem como para melhorar e analisar nosso Serviço.</span>
        </p>
        <p>
            <span>Você pode instruir seu navegador a rejeitar todos os cookies ou notificá-lo quando um cookie estiver sendo enviado. No entanto, se você não aceitar cookies, pode não conseguir usar algumas partes do nosso Serviço.</span>
        </p>
        <p>
            <span>Exemplos de cookies que usamos:</span>
        </p>
        <ul>
            <li>
                <span>Cookies de sessão.</span>
                <span>&nbsp;Usamos cookies de sessão para operar nosso serviço.</span>
            </li>
            <li>
                <span>Cookies de preferência.</span>
                <span>&nbsp;Usamos cookies de preferência para lembrar suas preferências e várias configurações.</span>
            </li>
            <li>
                <span>Cookies de segurança.</span>
                <span>&nbsp;Usamos cookies de segurança para fins de segurança.</span>
            </li>
        </ul>
        <h2>
            <span>Uso de dados</span>
        </h2>
        <p>
            <span>Nature Services Peru SA usa os dados coletados para diversos fins:</span>
        </p>
        <ul>
            <li>
                <span>Fornecer e manter nosso serviço</span>
            </li>
            <li>
                <span>Notificá-lo sobre mudanças em nosso serviço</span>
            </li>
            <li>
                <span>Permitir que você participe de recursos interativos de nosso Serviço quando você decidir fazê-lo</span>
            </li>
            <li>
                <span>Fornecer suporte ao cliente</span>
            </li>
            <li>
                <span>Colete análises que nos permitam melhorar nosso serviço</span>
            </li>
            <li>
                <span>Controle o uso do nosso serviço</span>
            </li>
            <li>
                <span>Detecte, evite e resolva problemas técnicos</span>
            </li>
            <li>
                <span>Para lhe oferecer novidades, ofertas especiais e informações gerais sobre outros bens, serviços e eventos que podemos oferecer e que são semelhantes aos que você já adquiriu ou sobre os quais fez perguntas, a menos que tenha optado por não receber essas informações.</span>
            </li>
        </ul>
        <h2>
            <span>Transferência de dados</span>
        </h2>
        <p>
            <span>Suas informações, incluindo Dados Pessoais, podem ser transferidas para - e mantidas em - computadores localizados fora de seu estado, província, país ou outra jurisdição governamental onde as leis de proteção de dados podem ser diferentes daquelas em sua jurisdição.</span>
        </p>
        <p>
            <span>Se você estiver fora do Peru e decidir nos fornecer informações, observe que transferimos os dados, incluindo Dados Pessoais, para o Peru e os processamos lá.</span>
        </p>
        <p>
            <span>A aceitação desta Política de Privacidade seguida do envio dessas informações representa sua concordância com essa transferência.</span>
        </p>
        <p>
            <span>Nature Services Peru SA tomará todas as medidas razoáveis ​​necessárias para garantir que seus dados sejam tratados com segurança e de acordo com esta Política de Privacidade e nenhuma transferência de seus Dados Pessoais será feita para uma organização ou país, a menos que haja controles adequados em vigor, incluindo o segurança de seus dados e outras informações pessoais.</span>
        </p>
        <h2>
            <span>Divulgação de dados</span>
        </h2>
        <h3>
            <span>Divulgação por obrigação legal</span>
        </h3>
        <p>
            <span>Em certas circunstâncias, a Nature Services Peru SA pode ser obrigada a divulgar seus Dados Pessoais quando exigido por lei ou em resposta a solicitações válidas de autoridades públicas (por exemplo, um tribunal ou órgão público).</span>
        </p>
        <h3>
            <span>Requerimentos legais</span>
        </h3>
        <p>
            <span>Nature Services Peru SA pode divulgar seus Dados Pessoais de boa fé quando considerar que esta ação é necessária para o seguinte:</span>
        </p>
        <ul>
            <li>
                <span>Cumprir uma obrigação legal</span>
            </li>
            <li>
                <span>Proteger e defender os direitos ou bens da Nature Services Peru SA</span>
            </li>
            <li>
                <span>Prevenir ou investigar possíveis infrações em relação ao Serviço</span>
            </li>
            <li>
                <span>Proteja a segurança pessoal dos usuários do Serviço ou do público</span>
            </li>
            <li>
                <span>Proteja-se das consequências legais</span>
            </li>
        </ul>
        <h2>
            <span>Segurança de dados</span>
        </h2>
        <p>
            <span>A segurança dos seus dados é importante para nós, mas lembre-se que nenhum método de transmissão pela Internet ou método de armazenamento eletrônico é 100% seguro. Embora nos esforcemos para usar meios comercialmente aceitáveis ​​para proteger seus Dados Pessoais, não podemos garantir sua segurança absoluta.</span>
        </p>
        <h2>
            <span>Provedores de serviço</span>
        </h2>
        <p>
            <span>Podemos contratar terceiros e pessoas jurídicas para facilitar nosso Serviço ("Provedores de Serviço"), para fornecer o Serviço em nosso nome, para fornecer serviços relacionados ao Serviço, ou para nos ajudar a analisar como nosso Serviço é usado.</span>
        </p>
        <p>
            <span>Esses terceiros têm acesso aos seus dados pessoais apenas para realizar essas tarefas em nosso nome e são obrigados a não divulgá-los ou usá-los para qualquer outra finalidade.</span>
        </p>
        <h3>
            <span>Pagamentos</span>
        </h3>
        <p>
            <span>Podemos oferecer produtos e / ou serviços de pagamento dentro do Serviço. Nesse caso, usamos serviços de terceiros para processamento de pagamentos (por exemplo, processadores de pagamentos). Fornecemos diretamente aos nossos processadores de pagamentos terceirizados, cujo uso de dados pessoais é regulado por sua Política de Privacidade. Esses processadores de pagamento atendem aos padrões definidos pelo padrão PCI-DSS gerenciado pelo comitê PCI SSC (PCI Security Standards Council), que é um esforço conjunto de marcas como Visa, MasterCard, American Express e Discover. Os requisitos do padrão PCI-DSS ajudam a garantir o gerenciamento seguro dos dados de pagamento.</span>
        </p>
        <p>
            <span>Os processadores de pagamento com os quais trabalhamos são: </span>
        </p>
        <p>
            <span>Stripe</span>
        </p>
        <p>
            <span>Sua Política de Privacidade pode ser consultada em </span>
            <span>
                <ExternalLink href="https://www.google.com/url?q=https://stripe.com/us/privacy&amp;sa=D&amp;ust=1571856898984000">https://stripe.com/us/privacy</ExternalLink>
            </span>
        </p>
        <h2>
            <span>Links para outros sites</span>
        </h2>
        <p>
            <span>Nosso serviço pode conter links para outros sites não operados por nós. Se você clicar em um link de terceiros, será direcionado para o site desse terceiro. Recomendamos enfaticamente que você analise a Política de Privacidade de cada site que visitar.</span>
        </p>
        <p>
            <span>Não temos qualquer controle ou responsabilidade pelo conteúdo, políticas de privacidade ou práticas de sites ou serviços de terceiros.</span>
        </p>
        <h2>
            <span>Privacidade menor</span>
        </h2>
        <p>
            <span>Nosso serviço não é direcionado a menores de 18 anos (doravante, "Menor").</span>
        </p>
        <p>
            <span>Não coletamos intencionalmente informações de identificação pessoal de crianças menores de 18 anos. Se você é um pai ou responsável e sabe que seu filho nos forneceu dados pessoais, entre em contato conosco. Se tomarmos conhecimento de que coletamos Dados Pessoais de menores sem a verificação do consentimento dos pais, tomaremos medidas para remover essas informações de nossos servidores.</span>
        </p>
        <h2>
            <span>Mudanças nesta Política de Privacidade</span>
        </h2>
        <p>
            <span>Podemos atualizar nossa Política de Privacidade periodicamente. Iremos notificá-lo de quaisquer alterações, publicando a nova Política de Privacidade nesta página.</span>
        </p>
        <p>
            <span>Iremos informá-lo por e-mail e / ou um aviso em destaque sobre nosso Serviço antes que a alteração entre em vigor e atualizaremos a "data efetiva" no início desta Política de Privacidade.</span>
        </p>
        <p>
            <span>Recomendamos que você reveja esta Política de Privacidade periodicamente para ver se alguma alteração foi feita. As alterações a esta Política de Privacidade entram em vigor quando publicadas nesta página.</span>
        </p>
        <h2>
            <span>contacte-nos</span>
        </h2>
        <p>
            <span>Se você tiver alguma dúvida sobre esta Política de Privacidade, entre em contato conosco: </span>
        </p>
        <ul>
            <li>
                <span>Por e-mail: {process.env.REACT_APP_REPLY_TO_EMAIL_ADDRESS}</span>
            </li>
            <li>
                <span>Visitando esta página em nosso site: <ExternalLink href={`https://{process.env.REACT_APP_BASE_URL}/privacy-policy`}>{process.env.REACT_APP_BASE_URL}/privacy-policy</ExternalLink></span>
            </li>
        </ul>
    </div>
  );
};

PortuguesePrivacyPolicy.defaultProps = {
    rootClassName: null,
    className: null,
};

const { string } = PropTypes;

PortuguesePrivacyPolicy.propTypes = {
    rootClassName: string,
    className: string,
};

export default PortuguesePrivacyPolicy;

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';

import Header from '../NewLandingPage/Sections/Header/Header';
import Footer from '../NewLandingPage/Sections/Footer/Footer';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Page } from '../../components';
import Button from '../../newComponents/Button';
import { OrangeBtn } from '../../newComponents/Button/ButtonSkin';

import HeroSection from '../../newComponents/HeroSection/HeroSection';
import friendsHeroDesktop from '../../assets/newAssets/friends/friends-hero-d.webp';
import friendsHeroMobile from '../../assets/newAssets/friends/friends-hero-m.webp';

import bgGreenTexture from '../../assets/newAssets/bg-texture-green.jpg';

import PlansComparison from '../../newComponents/PlansComparison';

import heroLeaf from '../../assets/newAssets/hero-leaf.png';

import FriendTestimonialContent from './Sections/FriendTestimonials';
import AllianceCompanies from './Sections/AllianceCompanies';
import ContactAction from './Sections/ContactAction';
import { FriendSection, PlanContainer } from './Styles';

import FriendsFaqs from '../../newComponents/Faqs';

const pragraph = (
    <FormattedHTMLMessage
        id="FriendsPage.Hero.Paragraph"
        values={{
            p: chunks => <p>{chunks}</p>,
        }}
    />
);

const questionData = [
    {
        id: 1,
        question: 'How much do I get paid?',
        answer:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    },
    {
        id: 2,
        question: 'When do I get paid?',
        answer:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    },
    {
        id: 3,
        question: 'What is carbon offsetting?',
        answer:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    },
    {
        id: 4,
        question: 'How do I “restore Nature”?',
        answer:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    },
    {
        id: 5,
        question: 'Why do I need all this paperwork to sign up?',
        answer:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    },
    {
        id: 6,
        question: 'What roles can I choose?',
        answer:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    },
];

const FriendsPage = ({ location, history, user }) => {
    const [planType, setPlanType] = useState('personas');

    return (
        <Page>
            <Header currentPath={location.pathname} user={user} history={history} />
            <FriendSection bgGreenTexture={bgGreenTexture}>
                <HeroSection
                    title={<FormattedMessage id="FriendsPage.Hero.Title" />}
                    titleline={<FormattedMessage id="FriendsPage.Hero.TitleLine" />}
                    titleclass="text-white"
                    paratext={pragraph}
                    paraclass="text-white"
                    heroBg="green-bg"
                    heroImgM={friendsHeroMobile}
                    heroImgD={friendsHeroDesktop}
                    alttxt="Friends"
                    orangeBtn
                    btnText={<FormattedMessage id="FriendsPage.Hero.SignupBtn" />}
                    btnLink="/subscription/signup"
                />

                <PlanContainer heroLeaf={heroLeaf}>
                    <div className="title-box text-center">
                        <h3>
                            <FormattedMessage id="FriendsPage.Plans.Title" />
                        </h3>
                    </div>

                    <div className="title-box text-center">
                        <Button
                            onClick={() => setPlanType('personas')}
                            variant={planType === 'personas' ? 'filled' : 'invertedGhost'}
                            testId="btn-friend-personal-plan"
                        >
                            <FormattedMessage id="FriendsPage.Plans.Intro.Individual" />
                        </Button>
                        <Button
                            variant={planType === 'familia' ? 'filled' : 'invertedGhost'}
                            onClick={() => setPlanType('familia')}
                            testId="btn-friend-family-plan"
                        >
                            <FormattedMessage id="FriendsPage.Plans.Intro.Family" />
                        </Button>
                    </div>
                    <Link className="text-center panel-link" to="/organizations">
                        <FormattedMessage id="FriendsPage.Plans.Intro.RegBusLink" />
                    </Link>

                    <div
                        className={`plan-group flex justify-center ${
                            planType === 'personas' ? 'showPlans' : 'hidePlans'
                        }`}
                    >
                        <PlansComparison user={user} userType={planType} />
                    </div>

                    <div
                        className={`plan-group flex justify-center ${
                            planType === 'familia' ? 'showPlans' : 'hidePlans'
                        }`}
                    >
                        <PlansComparison user={user} userType={planType} />
                    </div>

                    <div className="center-text-btn flex flex-col text-center">
                        <h3>
                            <FormattedMessage id="FriendsPage.Plans.Help.Choose" />
                        </h3>
                        <p>
                            <FormattedMessage id="FriendsPage.CalculateFootprint" />
                        </p>
                        <OrangeBtn>
                            <Link to="/calculator">
                                <FormattedMessage id="FriendsPage.CalculateFootprint.Btn" />
                            </Link>
                        </OrangeBtn>
                    </div>
                </PlanContainer>
                <FriendTestimonialContent />
                <FriendsFaqs questionData={questionData} />
                <AllianceCompanies
                    title={<FormattedMessage id="FriendsPage.companyLogos.title" />}
                />
            </FriendSection>
            <Footer history={history} />
        </Page>
    );
};

const mapStateToProps = storeState => ({
    user: storeState.user.currentUser
        ? {
              initials: storeState.user.currentUser.attributes.profile.abbreviatedName,
              name:
                  storeState.user.currentUser.attributes.profile.firstName +
                  ' ' +
                  storeState.user.currentUser.attributes.profile.lastName,
              email: storeState.user.currentUser.attributes.email,
              firstName: storeState.user.currentUser.attributes.profile.firstName,
              lastName: storeState.user.currentUser.attributes.profile.lastName,
              currentRole: storeState.user.currentUser.currentRole,
              cartCount: storeState.user.currentUser.cartCount,
          }
        : null,
});
export default connect(mapStateToProps)(withRouter(FriendsPage));

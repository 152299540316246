import React from 'react';
import SliderReview from 'react-slick';
import styled from 'styled-components';
import { OrangeBtn } from '../../../../newComponents/Button/ButtonSkin';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import borderImg from '../../../../assets/newAssets/title-border-small.png';

import bgGreenTexture from '../../../../assets/newAssets/bg-texture-green.jpg';

import testimonial_img1 from '../../../../assets/newAssets/testimonials/rufina-rivera.webp';
import testimonial_img2 from '../../../../assets/newAssets/testimonials/modesto_challco.webp';
import testimonial_img3 from '../../../../assets/newAssets/testimonials/zacarias_zambrano.webp';

const VidTestimonial = styled.section`
    background: #004532 url('../images/bg-texture-green.jpg') no-repeat center top;
    background-size: cover;
    padding: 90px 4.8rem 93px;
    text-align: center;

    .two-cols {
        max-width: 100%;
        flex: 1;
        gap: 80px;
        .testimonal-slider {
            box-shadow: 2px 4px 15px rgba(0, 0, 0, 0.05);
            border-radius: 8px;
            width: 100%;
            max-width: 480px;
            .slide-box {
                border-radius: 8px;
                background-color: white;
                padding: 40px 66px 66px;
                box-sizing: border-box;
                border-radius: 8px;

                .profile-info-parent {
                    gap: 18px;
                    .profile-img {
                        max-width: 150px;
                        text-align: center;
                        img {
                            border-radius: 50%;
                            max-width: 160px;
                            border: 3px solid #6ea44c;
                        }
                    }
                    .profile-info-quote {
                        font-size: 19px;
                        line-height: 1.3;
                        color: ${({ theme }) => theme.colors.title_green};
                        font-weight: 600;
                        gap: 5px;
                        .profile-name {
                            font-size: 16px;
                            font-weight: 400;
                            color: ${({ theme }) => theme.colors.grey};
                        }
                    }
                }
            }
        }
        .video-section {
            video {
                width: 100%;
                max-width: 596px;
                height: auto;
                border-radius: 8px;
            }
        }
        .testimonal-information {
            gap: 12px 0;
            text-align: left;
            max-width: 330px;
            h3.white-text {
                font-size: 48px;
                line-height: 58px;
                font-weight: 600;
                color: white;
                .border {
                    margin: 0 10px;
                    display: inline-block;
                    height: 60px;
                    img {
                        left: 2px;
                        bottom: -2px;
                        width: 182px;
                    }
                }
            }
            .parent-info {
                gap: 16px;
                .testimonial-text {
                    line-height: 180%;
                    color: white;
                }
            }
        }
    }
    @media (max-width: 1170px) {
        padding: 24px 20px 80px;
        .two-cols {
            flex-wrap: wrap;
            gap: 32px;
            padding: 0;
            .testimonal-slider {
                max-width: none;
                order: 1;
                box-shadow: none;
                .slick-slider {
                    margin-left: -20px;
                    margin-right: -20px;
                    .slick-dots {
                        bottom: -45px;
                    }
                }
                .slick-slide > div {
                    margin: 0 8px;
                }
                .slide-box {
                    box-shadow: 2px 4px 15px rgba(0, 0, 0, 0.05);
                    padding: 20px 16px 20px;
                    .profile-info-parent {
                        display: inline-block;
                        .profile-img {
                            margin-bottom: 8px;
                        }
                        .profile-info-quote {
                            display: inline-block;
                            .profile-quote {
                                margin-bottom: 4px;
                            }
                        }
                    }
                }
                .slick-center {
                    .profile-name {
                        margin-top: 6px;
                    }
                }
            }
            .video-section {
                margin-top: -50px;
                video {
                    width: 100%;
                }
            }

            .testimonal-information {
                order: 0;
                text-align: left;
                gap: 8px;
                max-width: max-content;
                h3.white-text {
                    font-size: 28px;
                    line-height: 38px;
                    .border {
                        img {
                            display: none;
                        }
                    }
                }
                .parent-info {
                    gap: 12px;
                    margin: 0 auto;
                }
            }
        }
    }
`;

const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,

    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                centerMode: true,
                className: 'center',
                arrows: false,
                dots: true,
                speed: 300,
                centerPadding: '35px',
                infinite: true,
            },
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                centerMode: true,
                className: 'center',
                arrows: false,
                dots: true,
                speed: 300,
                centerPadding: '35px',
                infinite: true,
                adaptiveHeight: true,
            },
        },
    ],
};
const GuardianTestimonialContent = ({ testimonialClass }) => {
    const testimonialData = [
        {
            id: 1,
            profileImg: testimonial_img1,
            profileQuote: <FormattedMessage id="GuardiansPage.testimonials.quote.rufinarivera" />,
            profileName: <FormattedMessage id="GuardiansPage.testimonials.name.rufinarivera" />,
            profilePost: (
                <FormattedMessage id="GuardiansPage.testimonials.designation.rufinarivera" />
            ),
        },
        {
            id: 2,
            profileImg: testimonial_img2,
            profileQuote: <FormattedMessage id="GuardiansPage.testimonials.quote.modestochallco" />,
            profileName: <FormattedMessage id="GuardiansPage.testimonials.name.modestochallco" />,
            profilePost: (
                <FormattedMessage id="GuardiansPage.testimonials.designation.modestochallco" />
            ),
        },
        {
            id: 3,
            profileImg: testimonial_img3,
            profileQuote: (
                <FormattedMessage id="GuardiansPage.testimonials.quote.zacariaszambrano" />
            ),
            profileName: <FormattedMessage id="GuardiansPage.testimonials.name.zacariaszambrano" />,
            profilePost: (
                <FormattedMessage id="GuardiansPage.testimonials.designation.zacariaszambrano" />
            ),
        },
    ];

    return (
        <VidTestimonial bgGreenTexture={bgGreenTexture} className={testimonialClass}>
            <div className="two-cols flex justify-center items-center">
                <div className="testimonal-information flex flex-col items-start">
                    <h3 className="white-text">
                        <FormattedMessage id="GuardiansPage.testimonials.titleOne" />
                        <span className="border relative">
                            <FormattedMessage id="GuardiansPage.testimonials.titleLine" />

                            <img src={borderImg} className="absolute w-full" alt="border" />
                        </span>
                        <FormattedMessage id="GuardiansPage.testimonials.titleTwo" />
                    </h3>

                    <div className="parent-info flex flex-col">
                        <div className="testimonial-text"></div>
                        <div className="button-box">
                            <OrangeBtn>
                                <Link to="/land-managers/role">
                                    <FormattedMessage id="GuardiansPage.testimonials.titleBtn" />
                                </Link>
                            </OrangeBtn>
                        </div>
                    </div>
                </div>

                <div className="testimonal-slider">
                    <SliderReview {...settings}>
                        {testimonialData.map(item => (
                            <div className="slide-box">
                                <div className="profile-info-parent flex flex-col">
                                    <div className="profile-img mx-auto">
                                        <img alt="profile img" src={item.profileImg} />
                                    </div>
                                    <div className="profile-info-quote flex flex-col">
                                        <div className="profile-quote">{item.profileQuote}</div>
                                        <div className="profile-name">
                                            <strong>{item.profileName}</strong> - {item.profilePost}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </SliderReview>
                </div>
            </div>
        </VidTestimonial>
    );
};

export default GuardianTestimonialContent;

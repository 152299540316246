import React from 'react';
import styles from './styles.module.css';
import { string, arrayOf, shape, number, bool, object } from 'prop-types';
import { withRouter } from 'react-router-dom';
import BlogTag from '../BlogTag';

const propTypes = {
    /** URL of the blog image */
    imageUrl: string,
    /** Title of the blog */
    title: string,
    /** Expected reading time for the blog */
    readTime: string,
    /** Array of tags */
    tags: arrayOf(shape({ id: string, name: string })),
    /** visibility of the blog */
    visible: bool,
    /** slug of the blog */
    slug: string,
    /** history object */
    history: object,
    /** summary of blog */
    summary: string,
    /** writer of blog */
    writer: string,
    /** publishDate of blog */
    publishDate: string,
};

const BlogSearchResultCard = ({ imageUrl, title, summary, writer, publishDate, readTime, tags, visible, slug, history }) => {
    const handleClick = slug => () => {
        history.push(`/news/${slug}`);
    };

    return (
        <div onClick={handleClick(slug)} className={`${styles.card} ${!visible && styles.hidden}`}>
            <img src={imageUrl} alt="blog" />
            <div className={styles.content}>
                <p className={styles.title}>{title}</p>
                <p className={styles.summary}>{summary}</p>
                <span className={styles.time}>By {writer}</span>
                <span className={styles.time}>{publishDate}</span>
                <p className={styles.time} style={{opacity:0.5}}>{readTime}min read</p>
                <div className={styles.tags}>
                    {tags ? tags.map(element => <BlogTag key={element.id} tagName={element.name} cardType='Blogcard'/>) : null}
                </div>
            </div>
        </div>
    );
};
BlogSearchResultCard.propTypes = propTypes;
BlogSearchResultCard.defaultProps = {
    visible: true,
};
export default withRouter(BlogSearchResultCard);

import React from 'react';
import styles from './styles.module.css';
import { string, object } from 'prop-types';
import { withRouter } from 'react-router-dom';

const propTypes = {
    tagName: string,
    cardType: string,
    history: object,
};

const BlogTag = ({ tagName, tagSlug, cardType, history }) => {
    const handleClickTag = tagSlug => e => {
        history.push(`/news/tag/${tagSlug}`);
        e.stopPropagation();
    };

    return (
        <span
            className={cardType === 'Blogcard' ? styles.BlogCard : styles.FeaturedCard}
            onClick={handleClickTag(tagSlug)}
        >
            #{tagName}
        </span>
    );
};

BlogTag.propTypes = propTypes;
export default withRouter(BlogTag);
